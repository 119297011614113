import { useNavigate } from 'react-router-dom';

import H1 from '../../components/H1';
import Link from '../../components/Link';
import useClients from '../../hooks/useClients';
import classNames from '../../lib/classNames';
import setTitle from '../../lib/setTitle';
import { trackOrganizationChanged } from '../../lib/tracking';

const HomePage = () => {
  setTitle('Pngme Dashboard');
  const navigate = useNavigate();
  const { authorizedClients, setActiveClient } = useClients();

  // Find organizations the dashboard user is authorized to view and return an
  // array of clients for the demo or production environments for each org.
  const clientOptions = authorizedClients.filter((client) =>
    ['demo', 'production'].includes(client.type),
  );
  clientOptions
    .sort((a, b) =>
      a.organizationName.toLowerCase() > b.organizationName.toLowerCase()
        ? 1
        : -1,
    )
    .sort((a, b) => (a.type === 'demo' ? -1 : 0));

  return (
    <div>
      <H1>Select organization</H1>

      <p className="mt-4">
        Organizations are top level groupings of related data. Create a new
        organization for each app you want to integrate with{' '}
        <span className="font-medium text-primary-600">Pngme</span>.
      </p>

      <ul className="mt-8 flex flex-wrap justify-center gap-4 sm:justify-start">
        {clientOptions.map((client) => (
          <li key={client.organizationId}>
            <div
              className="cursor-pointer"
              onClick={() => {
                setActiveClient(client);
                trackOrganizationChanged(client.organizationName);
                navigate('dashboard');
              }}
            >
              <div
                className={classNames(
                  'h-52 w-64 rounded-lg p-6',
                  client.type === 'demo'
                    ? 'bg-gray-50 hover:bg-gray-100'
                    : 'bg-secondary-50 hover:bg-secondary-100',
                )}
              >
                <div className="font-bold">{client.organizationName}</div>
                <div className="mt-2 text-sm text-gray-500">
                  {client.type === 'demo' && 'Explore sample data'}
                </div>
              </div>
            </div>
          </li>
        ))}
        <li>
          <Link primary to="/organization/create">
            <div className="h-52 w-64 rounded-lg bg-primary-100 p-6 hover:bg-primary-200">
              <div className="font-bold text-primary-800">+</div>
              <div className="mt-2 text-sm text-gray-500">
                Create a new organization
              </div>
            </div>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default HomePage;

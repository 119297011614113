import { ReactNode } from 'react';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';

import AuthProvider from './AuthProvider';
import TrackingProvider from './TrackingProvider';

type Props = {
  children: ReactNode;
};

// We use a singleton react-query client to provide a single global in-mem
// cache for API responses. The cache key includes the clientUuid of the
// activeClient, see useApiCache.ts.
const queryClient = new QueryClient();

/** Provides global settings for caching and notifications. */
const GlobalContextProvider = ({ children }: Props) => (
  <AuthProvider>
    <TrackingProvider>
      <QueryClientProvider client={queryClient}>
        <Toaster
          position="bottom-center"
          toastOptions={{
            className: 'text-sm',
            style: {
              background: '#0f172a',
              borderRadius: '999px',
              color: '#fff',
            },
          }}
        />

        {children}
      </QueryClientProvider>
    </TrackingProvider>
  </AuthProvider>
);

export default GlobalContextProvider;

import React, { ReactNode, useEffect, useState } from 'react';

import * as auth from '../lib/auth';
import * as cache from '../lib/cache';

type AuthContextValue = {
  authorizedClients: auth.Client[];
  setAuthorizedClients: (clients: auth.Client[]) => void;
  activeClient: auth.Client | null;
  setActiveClient: (client: auth.Client | null) => void;
};

export const AuthContext = React.createContext<AuthContextValue>({
  activeClient: null,
  authorizedClients: [],
  setActiveClient: () => {},
  setAuthorizedClients: () => {},
});

type Props = {
  children: ReactNode;
};

/** One way binding to sync auth state changes to localStorage. */
const AuthProvider = ({ children }: Props) => {
  const [activeClient, setActiveClient] = useState<auth.Client | null>(
    auth.getActiveClient,
  );
  const [authorizedClients, setAuthorizedClients] = useState(
    auth.getAuthorizedClients,
  );

  useEffect(() => {
    if (activeClient) {
      const clientId = {
        organizationId: activeClient.organizationId,
        type: activeClient.type,
      };
      cache.setActiveClientId(clientId);
    }
  }, [activeClient]);

  useEffect(() => {
    cache.setAuthorizedClients(authorizedClients);
    setActiveClient(auth.getActiveClient());
  }, [authorizedClients]);

  return (
    <AuthContext.Provider
      value={{
        activeClient,
        authorizedClients,
        setActiveClient,
        setAuthorizedClients,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import H2 from '../../components/H2';
import LoadingSpinner from '../../components/LoadingSpinner';
import NavLink from '../../components/NavLink';
import useClients from '../../hooks/useClients';
import useUserInstitutions from '../../hooks/useUserInstitutions';
import InstitutionData from './InstitutionData';

type Props = { userCreatedAt: Date; userId: string };

const PrimitiveData = ({ userCreatedAt, userId }: Props) => {
  const { activeClient } = useClients();
  const { institutions } = useUserInstitutions(userId, activeClient);

  const currentUTCTime = new Date().valueOf();

  // Getting the difference in time between the current UTC
  // time and the user's created_at time in ms
  const timeDifference = currentUTCTime - new Date(userCreatedAt).valueOf();

  const timeDifferenceInMinutes = Math.floor(timeDifference / 1000 / 60);
  if (!institutions.length && timeDifferenceInMinutes < 10) {
    return <p className="mt-4">User data is being processed.</p>;
  } else if (!institutions.length) {
    return <p className="mt-4">No accounts found.</p>;
  }

  return (
    <div className="mt-10 border-gray-100">
      <H2>All institutions ({institutions.length})</H2>

      <ul className="mt-4 flex flex-wrap gap-4">
        {institutions.map((institutionId) => (
          <li key={institutionId}>
            <NavLink to={institutionId} replace>
              <button className="truncate whitespace-nowrap text-sm capitalize">
                {institutionId}
              </button>
            </NavLink>
          </li>
        ))}
      </ul>

      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route index element={<Navigate to={institutions[0]} replace />} />
          {institutions.map((institutionId) => (
            <Route
              path={institutionId}
              key={institutionId}
              element={
                <InstitutionData
                  userId={userId}
                  institutionId={institutionId}
                />
              }
            />
          ))}
        </Routes>
      </Suspense>
    </div>
  );
};

export default PrimitiveData;

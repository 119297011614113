import dayjs from 'dayjs';

import Pill from '../../components/Pill';
import { AlertRecord } from '../../hooks/useUserInstitutionAlerts';

type Props = {
  alerts: AlertRecord[];
};

const AlertList = ({ alerts }: Props) => (
  <ul className="divide-y">
    {alerts.map((alert) => (
      <li
        key={alert.id}
        className="mt-4 flex items-center justify-between space-y-1 pt-3 text-sm"
      >
        <div>{dayjs(alert.timestamp).format('LL')}</div>

        <ul className="flex gap-2">
          {alert.labels.map((label) => (
            <li key={label}>
              <Pill secondary>{label}</Pill>
            </li>
          ))}
        </ul>
      </li>
    ))}
  </ul>
);

export default AlertList;

import toast from 'react-hot-toast';

import ClientTypePill from '../../components/ClientTypePill';
import ExternalLink from '../../components/ExternalLink';
import H2 from '../../components/H2';
import LabeledInput from '../../components/LabeledInput';
import useClients from '../../hooks/useClients';
import { trackSdkTokenCopied } from '../../lib/tracking';

const SDKTokensContainer = () => {
  const { activeClient, authorizedClients } = useClients();

  const clientsInOrganization = authorizedClients.filter(
    (client) => client.organizationId === activeClient.organizationId,
  );

  const testClient = clientsInOrganization.find(
    (client) => client.type === 'test',
  );
  const prodClient = clientsInOrganization.find(
    (client) => client.type === 'production',
  );

  return (
    <div>
      <H2>SDK Tokens</H2>

      <div className="mt-4">
        <ul className="space-y-1 rounded-lg bg-secondary-50 p-4 text-sm">
          <li>
            SDK tokens are used to integrate Pngme into your mobile app using
            our official{' '}
            <ExternalLink
              secondary
              href="https://github.com/pngme/sample-android-app-flutter"
            >
              Flutter
            </ExternalLink>
            ,{' '}
            <ExternalLink
              secondary
              href="https://github.com/pngme/sample-android-app-react-native"
            >
              React Native
            </ExternalLink>
            , and{' '}
            <ExternalLink
              secondary
              href="https://github.com/pngme/sample-android-app-kotlin"
            >
              Kotlin
            </ExternalLink>{' '}
            SDKs. SDK tokens do not expire.
          </li>

          <li>
            Use your test SDK tokens while you build and test your Pngme
            integration. Once you launch to production, switch to your
            production SDK and API tokens.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <div className="grid grid-cols-1 gap-x-2 sm:grid-cols-2">
          {testClient?.sdkToken && (
            <LabeledInput
              id="testSdkToken"
              label={
                <div>
                  <span>SDK token</span>
                  <span className="float-right">
                    <ClientTypePill clientType="test" />
                  </span>
                </div>
              }
              readOnly
              truncate
              value={testClient.sdkToken || 'Not authorized'}
              onClick={() =>
                testClient.sdkToken &&
                toast
                  .promise(
                    navigator.clipboard.writeText(testClient.sdkToken),
                    {
                      error: 'Unable to copy token to clipboard',
                      loading: null,
                      success: 'Copied SDK token to clipboard',
                    },
                    { id: 'clipboard' },
                  )
                  .then(() => trackSdkTokenCopied('test'))
              }
            />
          )}
          {prodClient?.sdkToken && (
            <LabeledInput
              id="prodSdkToken"
              label={
                <div>
                  <span className="float-right">
                    <ClientTypePill clientType="production" />
                  </span>
                </div>
              }
              readOnly
              truncate
              value={prodClient.sdkToken || 'Not authorized'}
              onClick={() =>
                prodClient.sdkToken &&
                toast
                  .promise(
                    navigator.clipboard.writeText(prodClient.sdkToken),
                    {
                      error: 'Unable to copy token to clipboard',
                      loading: null,
                      success: 'Copied SDK token to clipboard',
                    },
                    { id: 'clipboard' },
                  )
                  .then(() => trackSdkTokenCopied('production'))
              }
            />
          )}
        </div>
        {!testClient?.sdkToken && !prodClient?.sdkToken && (
          <p>
            You are not authorized to view SDK tokens within your current
            organization.
          </p>
        )}
      </div>
    </div>
  );
};

export default SDKTokensContainer;

import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const GlobalLayout = ({ children }: Props) => (
  <div className="min-h-screen min-w-full">
    <div className="container mx-auto max-w-6xl">
      <div className="font-sans text-sm text-gray-700 antialiased">
        {children}
      </div>
    </div>
  </div>
);

export default GlobalLayout;

import { API_BASE_URL, FetchError } from './api';
import { getClientProductionToken } from './auth';

export const leadFilterFields = [
  'count_betting_and_lottery_events_0_90',
  'count_loan_defaulted_events_0_90',
  'count_loan_missed_payment_events_0_90',
  'count_loan_opened_events_0_90',
  'count_loan_repayment_events_0_90',
  'count_user_shared_device_ids_0_10',
  'income_kes_per_year',
  'mpesa_sum_of_credits_0_90',
  'risk_score',
  'sum_of_credits_0_90',
  'sum_of_depository_balances_latest',
] as const;
export type LeadFilterField = (typeof leadFilterFields)[number];

export const leadFilterOperations = ['<', '>'] as const;
export type LeadFilterOperation = (typeof leadFilterOperations)[number];

const isFilterField = (field: any): field is LeadFilterField => {
  return !!leadFilterFields.find((x) => x === field);
};

const isFilterOperation = (
  operation: any,
): operation is LeadFilterOperation => {
  return !!leadFilterOperations.find((x) => x === operation);
};

const isFilterValue = (value: any): value is number => {
  return !isNaN(value);
};

type LeadFilter = {
  field: LeadFilterField;
  operation: LeadFilterOperation;
  value: number;
};

export type LeadsResponse = {
  leads: object[];
};

export const findValidFilters = (
  filters: { field: string; operation: string; value: number }[],
) => {
  return filters
    .filter(
      (filter): filter is LeadFilter =>
        isFilterField(filter.field) &&
        isFilterOperation(filter.operation) &&
        isFilterValue(filter.value),
    )
    .map(({ field, operation, value }) => ({ field, operation, value }));
};

export const getLeads = async (
  filters: { field: string; operation: string; value: number }[],
) => {
  const token = getClientProductionToken();

  const validFilters = findValidFilters(filters);

  const url = new URL('/beta/kenya/leads', API_BASE_URL);
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  const body = { filters: validFilters };
  const response = await fetch(url, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
  });

  if (!response.ok) {
    const error = new FetchError(await response.text());
    error.status = response.status;
    throw error;
  }

  const responseBody: LeadsResponse = await response.json();
  // .catch(() => ({}));

  return responseBody.leads;
};

export const getLeadsCount = async (
  filters: { field: string; operation: string; value: number }[],
) => {
  const token = getClientProductionToken();

  const validFilters = filters
    .filter(
      (filter): filter is LeadFilter =>
        isFilterField(filter.field) &&
        isFilterOperation(filter.operation) &&
        isFilterValue(filter.value),
    )
    .map(({ field, operation, value }) => ({ field, operation, value }));

  const url = new URL('/beta/kenya/leads/count', API_BASE_URL);
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };
  const body = { filters: validFilters };
  const response = await fetch(url, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
  });

  if (!response.ok) {
    const error = new FetchError(await response.text());
    error.status = response.status;
    throw error;
  }

  const responseBody: { count: number } = await response.json();
  return responseBody.count;
};

import { ReactNode } from 'react';

import classNames from '../lib/classNames';

type PillProps = {
  primary?: boolean;
  secondary?: boolean;
  children: ReactNode;
};

const Pill = ({ primary, secondary, children }: PillProps) => {
  var colors;
  if (primary) {
    colors = classNames(
      'bg-primary-100 text-primary-700',
      'hover:bg-primary-200 hover:text-primary-800',
    );
  } else if (secondary) {
    colors = classNames(
      'bg-secondary-100 text-secondary-700',
      'hover:bg-secondary-200 hover:text-secondary-800',
    );
  } else {
    colors = classNames(
      'bg-gray-100 text-gray-700',
      'hover:bg-gray-200 hover:text-gray-800',
    );
  }

  return (
    <span className={classNames(colors, 'rounded-full px-3 py-1 text-xs')}>
      {children}
    </span>
  );
};

export default Pill;

import { useContext } from 'react';

import { AuthContext } from '../context/AuthProvider';

/** Return the currently authorized clients within an authenticated context. */
const useClients = () => {
  const { activeClient, authorizedClients, setActiveClient } =
    useContext(AuthContext);

  if (!activeClient) {
    throw Error('useClients used outside authenticated context');
  }

  return {
    activeClient,
    authorizedClients,
    setActiveClient,
  };
};

export default useClients;

/**
 * Behavioral tracking utilities.
 */

/** Extend window interface to include tracking properties. */
declare global {
  interface Window {
    _hsq: any;
    gtag: any;
    fbq: any;
    twq: any;
  }
}

type SanitizedSignupOptions = {
  email: string;
  firstName: string;
  lastName: string;
  country: string;
  phoneNumber: string;
};

const { gtag, fbq, twq } = window;

export const trackApiTokenCopied = (environment: string) => {
  gtag('event', 'api_token_copied', { environment });
};

export const trackIdentity = (userId: string, email: string) => {
  const hsq = window._hsq || [];

  hsq.push(['identify', { email }]);
  gtag('config', 'G-JQL2GZLGH3', {
    user_id: userId, // eslint-disable-line camelcase
  });
};

export const trackOrganizationChanged = (organizationName: string) => {
  gtag('event', 'org_changed', { organizationName });
};

export const trackOrganizationCreated = (organizationName: string) => {
  gtag('event', 'org_created', { organizationName });
};

export const trackOrganizationMemberInvited = (
  organizationId: string,
  organizationName: string,
  email: string,
) => {
  gtag('event', 'org_member_invited', {
    email,
    organizationId,
    organizationName,
  });
};

export const trackSdkTokenCopied = (environment: string) => {
  gtag('event', 'sdk_token_copied', { environment });
};

export const trackSignup = (options: SanitizedSignupOptions) => {
  gtag('event', 'sign_up');
  hubspotFormSignup(options);
};

/** Emit page views to social tracking and custom behavioral events. */
export const trackPageView = () => {
  // google
  if (gtag) {
    gtag('event', 'page_view');
  }

  // twitter
  if (twq) {
    twq('init', 'o2nvq');
    twq('track', 'PageView');
  }

  // facebook
  if (fbq) {
    fbq('init', '814515732525007');
    fbq('track', 'PageView');
  }
};

const hubspotFormSignup = (options: SanitizedSignupOptions) => {
  const { email, firstName, lastName, country, phoneNumber } = options;

  const postData = JSON.stringify({
    context: {
      pageName: 'Signup',
      pageUri: window.location.href,
    },
    fields: [
      {
        name: 'email',
        objectTypeId: '0-1',
        value: email,
      },
      {
        name: 'firstname',
        objectTypeId: '0-1',
        value: firstName,
      },
      {
        name: 'lastname',
        objectTypeId: '0-1',
        value: lastName,
      },
      //
      {
        name: 'country',
        objectTypeId: '0-1',
        value: country,
      },
      {
        name: 'phone',
        objectTypeId: '0-1',
        value: phoneNumber,
      },
    ],
    legalConsentOptions: {
      consent: {
        communications: [
          {
            subscriptionTypeId: 999,
            text: 'I agree to receive marketing communications from Pngme.',
            value: true,
          },
        ],
        // Include this object when GDPR options are enabled
        consentToProcess: true,
        text: 'I agree to allow Pngme to store and process my personal data.',
      },
    },
  });

  fetch(
    'https://api.hsforms.com/submissions/v3/integration/submit/5108239/6f4d18d2-5805-456c-9af9-8fb3facbf3d2',
    {
      body: postData,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    },
  ).catch(console.error);
};

import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import H1 from '../../components/H1';
import H2 from '../../components/H2';
import useAuth from '../../hooks/useAuth';
import useDashboardUser from '../../hooks/useDashboardUser';
import { resetPassword } from '../../lib/auth';
import setTitle from '../../lib/setTitle';
import ProfileForm from './ProfileForm';

const SettingsPage = () => {
  setTitle('Settings');
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { dashboardUser } = useDashboardUser();

  const handleResetPassword = () => {
    const email = dashboardUser.email;
    toast
      .promise(resetPassword(email), {
        error: 'Error resetting your password',
        loading: 'Sending password reset email...',
        success: 'Check your email inbox',
      })
      .then(logout)
      .then(() => {
        const searchParams = new URLSearchParams({ email });
        const path = '/update-password?' + searchParams.toString();
        navigate(path);
      });
  };

  return (
    <div className="mx-auto max-w-2xl">
      <H1>Settings</H1>

      <div className="mt-4">
        <ProfileForm />
      </div>

      <div className="mt-12">
        <H2>Update password</H2>

        <p className="mt-4">
          We'll send you an email confirmation code which you can use to update
          your current password.
        </p>

        <div className="mt-4">
          <Button primary onClick={handleResetPassword}>
            Update password
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;

import { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import toast from 'react-hot-toast';

import Button from '../../components/Button';
import H2 from '../../components/H2';
import Select from '../../components/Select';
import useClients from '../../hooks/useClients';
import useConsoleUserToken from '../../hooks/useConsoleUserToken';
import useOrganizationMembers from '../../hooks/useOrganizationMembers';
import { inviteMember, removeMember, updateMember } from '../../lib/members';
import { trackOrganizationMemberInvited } from '../../lib/tracking';
import validate from '../../lib/validate';

const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
if (!recaptchaSiteKey) {
  throw Error('Unable to start without valid captcha site key.');
}

type Props = {
  organizationId: string;
  organizationName: string;
};

const MembersContainer = ({ organizationId, organizationName }: Props) => {
  const recaptchaRef = useRef<any>();
  const { activeClient } = useClients();
  const { members, refreshMembers } = useOrganizationMembers(organizationId);
  const { claims } = useConsoleUserToken();

  const handleInviteMember = (email: string) => {
    const isEmailValid = validate.email(email);
    if (!isEmailValid) {
      toast.error('Invalid email address');
      return;
    }

    toast
      .promise(
        recaptchaRef.current
          .executeAsync()
          .then((recaptchaResponse: string) =>
            inviteMember(organizationId, email, recaptchaResponse),
          ),
        {
          error: 'Error inviting member',
          loading: 'Inviting member...',
          success: 'Invite successful',
        },
        { id: 'organization-member-invite' },
      )
      .then(refreshMembers)
      .then(() =>
        trackOrganizationMemberInvited(organizationId, organizationName, email),
      )
      .finally(() => recaptchaRef.current.reset());
  };

  const handleUpdateMember = (email: string, role: string) => {
    toast
      .promise(
        updateMember(organizationId, email, role),
        {
          error: 'Error updating member',
          loading: 'Updating member...',
          success: 'Member update successful',
        },
        { id: 'organization-member-update' },
      )
      .then(refreshMembers);
  };

  const handleRemoveMember = (email: string) => {
    toast
      .promise(
        removeMember(organizationId, email),
        {
          error: 'Error removing member',
          loading: 'Removing member...',
          success: 'Member removed',
        },
        { id: 'organization-member-remove' },
      )
      .then(() => {
        if (email === claims.email) {
          window.location.reload();
        } else {
          refreshMembers();
        }
      });
  };

  const sortedMembers = members.filter(
    (member) => member.email !== 'internal@pngme.com',
  );
  sortedMembers.sort((a, b) => {
    if (a.role === 'owner') {
      return -1;
    }
    if (a.isInvited) {
      return 1;
    }
    if (!(a.firstName && b.firstName)) {
      return 0;
    }
    return a.firstName < b.firstName ? -1 : 1;
  });

  return (
    <div className="py-8">
      <div className="flex w-full items-center justify-between pr-2">
        <H2>Members</H2>
        <Button
          onClick={() => {
            const inviteeEmail = prompt(
              `Invite a member to ${activeClient?.organizationName} using their email address`,
            );
            if (inviteeEmail) {
              handleInviteMember(inviteeEmail);
            }
          }}
          disabled={!['admin', 'owner'].includes(claims.organizationRole)}
        >
          Invite
        </Button>
      </div>

      <div className="mt-4">
        <ul className="space-y-1 rounded-lg bg-secondary-50 p-4 text-sm">
          <li>
            <b>Basic</b> members can create API tokens but cannot revoke
            existing tokens and cannot view SDK tokens.
          </li>
          <li>
            <b>Admin</b> members can view API and SDK tokens, revoke existing
            API tokens, and manage organization members.
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <table className="min-w-full text-sm">
          <tbody className="divide-y divide-gray-200">
            {sortedMembers.map((member) => {
              return (
                <tr key={member.email}>
                  <td className="p-3 pl-2">
                    {!member.isInvited ? (
                      <span className="font-medium">
                        {member.firstName} {member.lastName}
                      </span>
                    ) : (
                      <span className="text-gray-400">Invited</span>
                    )}
                  </td>
                  <td className="truncate p-3">{member.email}</td>
                  <td className="p-3">
                    {member.role === 'owner' ? (
                      <div className="px-4 text-gray-400">Owner</div>
                    ) : (
                      <Select
                        id="role"
                        value={member.role}
                        onChange={(event) =>
                          handleUpdateMember(member.email, event.target.value)
                        }
                        disabled={
                          member.email === claims.email ||
                          !['admin', 'owner'].includes(claims.organizationRole)
                        }
                        transparent
                      >
                        <option value={'admin'}>Admin</option>
                        <option value={'basic'}>Basic</option>
                      </Select>
                    )}
                  </td>
                  <td className="p-3 pr-0 text-right">
                    <Button
                      transparent
                      disabled={
                        member.role === 'owner' ||
                        (!['admin', 'owner'].includes(
                          claims.organizationRole,
                        ) &&
                          member.email !== claims.email)
                      }
                      onClick={() =>
                        window.confirm(
                          `Are you sure you want to remove ${member.firstName} ${member.lastName} (${member.email})?`,
                        ) && handleRemoveMember(member.email)
                      }
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={recaptchaSiteKey}
        size="invisible"
      />
    </div>
  );
};

export default MembersContainer;

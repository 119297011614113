import { AnchorHTMLAttributes } from 'react';

import classNames from '../lib/classNames';

type Props = {
  primary?: boolean;
  secondary?: boolean;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

const ExternalLink = ({ primary, secondary, children, ...props }: Props) => {
  var color;
  if (primary) {
    color = 'text-primary-600 hover:text-primary-700';
  } else if (secondary) {
    color = 'text-secondary-500 hover:text-secondary-500';
  } else {
    color = 'text-gray-800 hover:text-gray-900';
  }
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      {...props}
      className={classNames(color, 'font-medium')}
    >
      {children}
    </a>
  );
};

export default ExternalLink;

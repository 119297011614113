import classNames from '../../lib/classNames';
import Button from '../Button';

type PaginationBlockProps = {
  pageNumbers: number[];
  onClick: (selected: number) => void;
  currentPage: number;
};

export const PaginationBlock = ({
  pageNumbers,
  onClick,
  currentPage,
}: PaginationBlockProps) => {
  return (
    <>
      {pageNumbers.map((pageNumber) => {
        return (
          <div
            key={pageNumber}
            className={classNames(
              pageNumber === currentPage
                ? 'bg-gray-100 hover:bg-gray-200'
                : 'hover:border hover:border-gray-100',
              'rounded-md border border-transparent',
            )}
          >
            <Button
              transparent
              key={pageNumber}
              onClick={() => onClick(pageNumber)}
            >
              {pageNumber}
            </Button>
          </div>
        );
      })}
    </>
  );
};

import { XMarkIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';

import H3 from '../../components/H3';
import { Pagination } from '../../components/Pagination';
import Pill from '../../components/Pill';
import useClients from '../../hooks/useClients';
import useUserInstitutionAlerts from '../../hooks/useUserInstitutionAlerts';
import useUserInstitutionTransactions from '../../hooks/useUserInstitutionTransactions';
import AlertList from './AlertList';
import TransactionList from './TransactionList';

type Props = {
  userId: string;
  institutionId: string;
};

const InstitutionData = ({ userId, institutionId }: Props) => {
  const [page, setPage] = useState(1);
  const { activeClient } = useClients();
  const { transactions, numPages } = useUserInstitutionTransactions(
    userId,
    institutionId,
    page,
    activeClient,
  );
  const { alerts } = useUserInstitutionAlerts(
    userId,
    institutionId,
    activeClient,
  );
  const [activeAlertLabel, setActiveAlertLabel] = useState<string | null>(null);

  useEffect(() => setActiveAlertLabel(null), [institutionId]);

  // count number of occurrences for each label using a hash map
  const alertCountByLabel =
    alerts.length > 0 &&
    alerts.reduce<{
      [label: string]: number;
    }>((prev, { labels }) => {
      const newLabelCounts = labels
        .map((label) => ({
          [label]: (prev[label] || 0) + 1,
        }))
        .reduce((memo, next) => ({ ...memo, ...next }));
      return { ...prev, ...newLabelCounts };
    }, {});

  // cast hash map to an array of labels and counts
  const alertCounts =
    alertCountByLabel &&
    Object.entries(alertCountByLabel).map(([label, count]) => ({
      count,
      label,
    }));

  const filteredAlerts =
    alerts.length > 0 &&
    alerts.filter((alert) =>
      activeAlertLabel ? alert.labels.includes(activeAlertLabel) : false,
    );

  return (
    <div>
      {alertCounts && (
        <div className="mt-8">
          <H3>Alerts</H3>
          <ul className="mt-4 flex flex-wrap gap-2">
            {alertCounts.map(({ label, count }) => (
              <li
                className="cursor-pointer"
                key={label}
                onClick={() =>
                  setActiveAlertLabel((prevLabel) =>
                    prevLabel === label ? null : label,
                  )
                }
              >
                <Pill secondary>
                  <span
                    className={activeAlertLabel === label ? 'font-bold' : ''}
                  >
                    {count} &middot; {label}{' '}
                    {activeAlertLabel === label ? (
                      <XMarkIcon className="mb-0.5 inline h-3 w-3" />
                    ) : (
                      ''
                    )}
                  </span>
                </Pill>
              </li>
            ))}
          </ul>
        </div>
      )}

      {activeAlertLabel ? (
        <div className="mt-6">
          {filteredAlerts && filteredAlerts.length ? (
            <AlertList alerts={filteredAlerts} />
          ) : (
            <div className="mt-6 text-sm">
              No alerts found for{' '}
              <span className="font-medium capitalize text-secondary-600">
                {institutionId}
              </span>
              .
            </div>
          )}
        </div>
      ) : (
        <div className="mt-8">
          <H3>Transactions</H3>
          <div>
            {transactions.length ? (
              <>
                <TransactionList transactions={transactions} />
                <div className="mt-5 flex items-center justify-center">
                  <Pagination
                    numPages={numPages}
                    currentPage={page}
                    handleChange={setPage}
                  />
                </div>
              </>
            ) : (
              <div className="mt-4 text-sm">
                No transactions found for{' '}
                <span className="font-medium capitalize">{institutionId}</span>.
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default InstitutionData;

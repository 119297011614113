import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { trackPageView } from '../lib/tracking';

type Props = {
  children: ReactNode;
};

const TrackingProvider = ({ children }: Props) => {
  const location = useLocation();

  useEffect(() => {
    trackPageView();
  }, [location]);

  return <>{children}</>;
};

export default TrackingProvider;

/**
 * This module exports persistence utilities and other helpers for
 * authentication and authorization using localStorage.
 *
 * The active client id does not contain sensitive credentials and is not
 * cleared on logout to preserve the active client on subsequent logins.
 *
 * This is coupled to JWT decoding utilities in auth.ts.
 */

import { Client, ClientType } from './auth';

const ACTIVE_CLIENT_ID_KEY = 'active-client-id';
const AUTHORIZED_CLIENTS_KEY = 'authorized-clients';

export type ClientId = {
  organizationId: string;
  type: ClientType;
};

/** Remove active client id from localStorage. */
export const removeActiveClientId = () => {
  localStorage.removeItem(ACTIVE_CLIENT_ID_KEY);
};

/** Persist active client id to localStorage. */
export const setActiveClientId = (clientId: ClientId) => {
  localStorage.setItem(ACTIVE_CLIENT_ID_KEY, JSON.stringify(clientId));
};

/** Load active client id from localStorage. */
export const getActiveClientId = (): ClientId | null => {
  const activeClientIdJson = localStorage.getItem(ACTIVE_CLIENT_ID_KEY);
  if (activeClientIdJson) {
    return JSON.parse(activeClientIdJson);
  }
  return null;
};

/** Remove credentials from localStorage. */
export const removeAuthorizedClients = () => {
  localStorage.removeItem(AUTHORIZED_CLIENTS_KEY);
};

/** Load authorized clients from localStorage. */
export const getAuthorizedClients = (): Client[] => {
  const clientsJson = localStorage.getItem(AUTHORIZED_CLIENTS_KEY);
  if (clientsJson) {
    return JSON.parse(clientsJson);
  }
  return [];
};

/** Persist active and authorized clients to localStorage. */
export const setAuthorizedClients = (clients: Client[]) => {
  localStorage.setItem(AUTHORIZED_CLIENTS_KEY, JSON.stringify(clients));
};

import useApiCache from './useApiCache';
import useConsoleUserToken from './useConsoleUserToken';

export type Response = {
  firstName: string;
  lastName: string;
  meta: Object;
  createdAt: Date;
  updatedAt: Date;
  email: string;
  userUuid: string;
};

const useDashboardUser = () => {
  const { token, claims } = useConsoleUserToken();
  const path = `/beta/dashboard/users/${claims.sub}`;
  const { data, error, refresh } = useApiCache<Response>(path, token);
  return {
    dashboardUser: data,
    error,
    refreshDashboardUser: refresh,
  };
};

export default useDashboardUser;

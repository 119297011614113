/**
 * This module exposes organization member administration services.
 */

import { deleteWithToken, postWithToken, putWithToken } from './api';
import { getConsoleUserToken } from './auth';

/** Add a member to an organization. */
export const inviteMember = async (
  organizationUuid: string,
  email: string,
  recaptchaResponse: string,
) => {
  const token = getConsoleUserToken();
  await postWithToken<null>(
    `/beta/dashboard/organizations/${organizationUuid}/members`,
    { email, recaptcha_response: recaptchaResponse, role: 'basic' }, // eslint-disable-line camelcase
    token,
  );
};

/** Update an organization member. */
export const updateMember = async (
  organizationUuid: string,
  email: string,
  role: string,
) => {
  const token = getConsoleUserToken();
  await putWithToken<null>(
    `/beta/dashboard/organizations/${organizationUuid}/members`,
    { email, role },
    token,
  );
};

/** Remove an organization member. */
export const removeMember = async (organizationUuid: string, email: string) => {
  const token = getConsoleUserToken();
  await deleteWithToken<null>(
    `/beta/dashboard/organizations/${organizationUuid}/members`,
    { email },
    token,
  );
};

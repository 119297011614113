import { FormEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Button from '../../components/Button';
import H2 from '../../components/H2';
import LabeledInput from '../../components/LabeledInput';
import Link from '../../components/Link';
import TextLogo from '../../components/TextLogo';
import useAuth from '../../hooks/useAuth';
import setTitle from '../../lib/setTitle';
import validate from '../../lib/validate';

const LoginPage = () => {
  setTitle('Login');

  const [searchParams] = useSearchParams();
  const defaultEmail = searchParams.get('email') || '';

  const { login } = useAuth();

  const [email, setEmail] = useState(defaultEmail);
  const [password, setPassword] = useState('');

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false);

  useEffect(() => {
    setShowPasswordRequirements((prev) => prev || !isPasswordValid);
  }, [isPasswordValid]);

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    if (!validate.email(email)) {
      setIsEmailValid(false);
      return;
    }

    if (!validate.password(password)) {
      setIsPasswordValid(false);
      return;
    }

    setIsLoading(true);
    try {
      await login(email, password);
    } catch (error) {
      setIsLoading(false);
      setIsEmailValid(false);
      setIsPasswordValid(false);
      console.error({ error });
    }
  };

  const emailAsQueryParameter = validate.email(email)
    ? `?email=${encodeURIComponent(email)}`
    : '';

  return (
    <div className="w-full md:w-96">
      <div className="flex justify-center py-12">
        <div className="cursor-default text-4xl">
          <TextLogo />
        </div>
      </div>

      <H2>Sign in to your account</H2>

      <form className="w-full space-y-4" onSubmit={handleFormSubmit}>
        <LabeledInput
          id="email"
          label="Email"
          type="text"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          onBlur={() => setIsEmailValid(validate.email(email))}
          error={!isEmailValid}
        />

        <LabeledInput
          id="password"
          label={
            <span className="flex justify-between">
              <label htmlFor="password">Password</label>
              <Link primary to={`/forgot-password${emailAsQueryParameter}`}>
                Forgot your password?
              </Link>
            </span>
          }
          type="password"
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
            setIsPasswordValid(true);
          }}
          error={!isPasswordValid}
          subText={
            showPasswordRequirements
              ? 'Minimum 8 characters, at least one capital letter, number, and special character.'
              : undefined
          }
        />

        <Button disabled={isLoading} wide>
          Continue
        </Button>
      </form>

      <div className="mt-6 text-sm">
        Don't have an account?{' '}
        <span className="font-medium">
          <Link primary to="/signup">
            Sign up
          </Link>
        </span>
      </div>
    </div>
  );
};

export default LoginPage;

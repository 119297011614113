import { FormEvent, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from '../../components/Button';
import ExternalLink from '../../components/ExternalLink';
import H2 from '../../components/H2';
import LabeledInput from '../../components/LabeledInput';
import Link from '../../components/Link';
import TextLogo from '../../components/TextLogo';
import { updatePassword } from '../../lib/auth';
import setTitle from '../../lib/setTitle';
import validate from '../../lib/validate';

const UpdatePasswordPage = () => {
  setTitle('Forgot password');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [email, setEmail] = useState(searchParams.get('email') || '');
  const [confirmationCode, setConfirmationCode] = useState(
    searchParams.get('confirmationCode') || '',
  );
  const [password, setPassword] = useState('');

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isConfirmationCodeValid, setIsConfirmationCodeValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const isFormValid =
    !isLoading && isEmailValid && isConfirmationCodeValid && isPasswordValid;

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isFormValid) {
      return;
    }

    setIsLoading(true);
    await updatePassword(email, confirmationCode, password)
      .then(() => navigate('/login'))
      .catch(() => {
        setIsLoading(false);
        setIsEmailValid(false);
        setIsConfirmationCodeValid(false);
      });
  };

  return (
    <div className="w-full md:w-96">
      <div className="flex justify-center py-12">
        <div className="cursor-default text-4xl">
          <TextLogo />
        </div>
      </div>

      <H2>Update your password</H2>

      <p className="mt-2 text-sm">
        Enter the email address associated with your account and the
        confirmation code we sent to your email.
      </p>

      <form className="w-full space-y-4" onSubmit={handleFormSubmit}>
        <LabeledInput
          id="email"
          label="Email"
          type="text"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          onBlur={() => setIsEmailValid(validate.email(email))}
          error={!isEmailValid}
        />

        <LabeledInput
          id="confirmationCode"
          label={
            <span className="flex justify-between">
              <label htmlFor="confirmationCode">Confirmation code</label>
              <Link primary to="/forgot-password">
                Need a confirmation code?
              </Link>
            </span>
          }
          type="confirmationCode"
          value={confirmationCode}
          onChange={(event) => {
            setConfirmationCode(event.target.value);
          }}
          onBlur={() =>
            setIsConfirmationCodeValid(confirmationCode.length === 6)
          }
          error={!isConfirmationCodeValid}
          subText={
            !isConfirmationCodeValid ? 'Minimum 6 digit number.' : undefined
          }
        />

        <LabeledInput
          id="password"
          label="Password"
          type="password"
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          onBlur={() => setIsPasswordValid(validate.password(password))}
          error={!isPasswordValid}
          subText={
            !isPasswordValid
              ? 'Minimum 8 characters, at least one capital letter, number, and special character.'
              : undefined
          }
        />

        <Button disabled={isLoading} wide>
          Continue
        </Button>

        <div className="flex justify-center">
          <ExternalLink primary onClick={() => navigate(-1)}>
            <div className="cursor-pointer px-4 py-1 text-sm">Back</div>
          </ExternalLink>
        </div>
      </form>
    </div>
  );
};

export default UpdatePasswordPage;

import Button from '../../components/Button';
import H1 from '../../components/H1';
import Link from '../../components/Link';
import UndrawTeam from '../../static/img/undraw_team_primary.svg';

const FeatureNotEnabledContainer = () => (
  <div className="flex justify-center">
    <div className="mt-12 w-full md:w-96">
      <div className="flex justify-center">
        <img src={UndrawTeam} className="mb-8 h-32" alt="Team of people" />
      </div>

      <H1>Lead Management</H1>

      <div className="mt-2 text-sm">
        Your organization has not enabled this feature.
        {/* TODO: brief description of lead management value prop */}
      </div>

      <div className="mt-6">
        <Link primary to="mailto:sales@pngme.com?subject=Pngme Lead Management">
          <Button wide>Contact Sales</Button>
        </Link>{' '}
      </div>
    </div>
  </div>
);

export default FeatureNotEnabledContainer;

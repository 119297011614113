import ExternalLink from '../../components/ExternalLink';
import H1 from '../../components/H1';
import useClients from '../../hooks/useClients';
import setTitle from '../../lib/setTitle';
import UndrawAndroid from '../../static/img/undraw_android_primary.svg';
import UndrawFinancialData from '../../static/img/undraw_financial_data_primary.svg';
import UndrawProjections from '../../static/img/undraw_projections_secondary.svg';
import FeatureCard from './FeatureCard';

const DashboardPage = () => {
  setTitle('Dashboard');
  const { activeClient } = useClients();

  return (
    <div>
      <H1>Welcome to {activeClient.organizationName}!</H1>

      {/* TODO: add lead management reference to dashboard home page */}
      <div className="mt-20 grid grid-cols-1 gap-x-6 gap-y-12 md:grid-cols-2 lg:grid-cols-3">
        <FeatureCard
          imageSrc={UndrawProjections}
          imageAlt="Exploring financial data"
          title="Explore Pngme enhanced data"
          body="Search and explore mobile phone users' data."
          buttonHref="/users"
          buttonText="Explore data"
        />

        <FeatureCard
          imageSrc={UndrawAndroid}
          imageAlt="Android phone"
          title="Build / SDK"
          body={
            <>
              {activeClient?.type === 'demo'
                ? 'Create an organization to integrate'
                : 'Integrate'}{' '}
              Pngme into your mobile app with our official{' '}
              <ExternalLink
                primary
                href="https://github.com/pngme/sample-android-app-flutter"
              >
                Flutter
              </ExternalLink>
              ,{' '}
              <ExternalLink
                primary
                href="https://github.com/pngme/sample-android-app-flutter_java"
              >
                Flutter Java
              </ExternalLink>
              ,{' '}
              <ExternalLink
                primary
                href="https://github.com/pngme/sample-android-app-java"
              >
                Java
              </ExternalLink>
              ,{' '}
              <ExternalLink
                primary
                href="https://github.com/pngme/sample-android-app-react-native"
              >
                React Native
              </ExternalLink>
              ,{' '}
              <ExternalLink
                primary
                href="https://github.com/pngme/sample-android-app-react-native-expo"
              >
                Expo
              </ExternalLink>
              , and{' '}
              <ExternalLink
                primary
                href="https://github.com/pngme/sample-android-app-kotlin"
              >
                Kotlin
              </ExternalLink>{' '}
              SDKs.{' '}
              {activeClient?.type === 'demo' &&
                'Free up to 1,000 active users with no strings attached!'}
            </>
          }
          buttonHref={
            activeClient?.type === 'demo'
              ? '/organization/create'
              : '/organization'
          }
          buttonText={
            activeClient?.type === 'demo'
              ? 'Create organization'
              : 'View SDK tokens'
          }
        />

        <FeatureCard
          imageSrc={UndrawFinancialData}
          imageAlt="Financial data on computer screen"
          title="Build / REST APIs"
          body={
            <>
              Use Pngme's{' '}
              <ExternalLink
                primary
                href="https://developers.api.pngme.com/reference/"
              >
                REST APIs
              </ExternalLink>{' '}
              to integrate Pngme enhanced data into your services.
            </>
          }
          buttonHref="/organization"
          buttonText="View API tokens"
        />
      </div>
    </div>
  );
};

export default DashboardPage;

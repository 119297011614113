import { FormEvent, useState } from 'react';
import toast from 'react-hot-toast';

import Button from '../../components/Button';
import LabeledInput from '../../components/LabeledInput';
import useDashboardUser from '../../hooks/useDashboardUser';
import { updateProfile } from '../../lib/auth';

const ProfileForm = () => {
  const { dashboardUser, refreshDashboardUser } = useDashboardUser();
  const [firstName, setFirstName] = useState(dashboardUser.firstName);
  const [lastName, setLastName] = useState(dashboardUser.lastName);

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!(firstName && lastName)) {
      return;
    }

    await toast
      .promise(
        updateProfile(firstName, lastName, dashboardUser.userUuid),
        {
          error: 'Failed to update your profile',
          loading: 'Updating profile...',
          success: 'Updated your profile',
        },
        { id: 'profile-update' },
      )
      .finally(refreshDashboardUser);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="grid grid-cols-3">
        <div className="col-span-3 md:col-span-2">
          <LabeledInput
            id="email"
            label="Email"
            value={dashboardUser.email}
            disabled
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 md:space-x-4">
        <LabeledInput
          id="firstName"
          label="First name"
          type="text"
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
        />
        <LabeledInput
          id="lastName"
          label="Last name"
          type="text"
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
        />
      </div>

      <Button primary>Update profile</Button>
    </form>
  );
};

export default ProfileForm;

import { Suspense, useDeferredValue, useState } from 'react';

import ExternalLink from '../../components/ExternalLink';
import H1 from '../../components/H1';
import Input from '../../components/Input';
import Link from '../../components/Link';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Pagination } from '../../components/Pagination';
import Select from '../../components/Select';
import useClients from '../../hooks/useClients';
import useDebounce from '../../hooks/useDebounce';
import setTitle from '../../lib/setTitle';
import UserList from './UserList';

const UsersPage = () => {
  setTitle('Data');
  const { activeClient, setActiveClient, authorizedClients } = useClients();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(useDeferredValue(search));

  const activeOrganizationClients = authorizedClients.filter(
    (client) => client.organizationId === activeClient?.organizationId,
  );
  const clientTypeOptions = activeOrganizationClients
    .map((client) => client.type)
    .filter((clientType) => clientType !== 'console_user');

  const handleChangeClientType = (clientType: string) => {
    const client = activeOrganizationClients.find(
      (activeOrganizationClient) =>
        activeOrganizationClient.type === clientType,
    );
    client && setActiveClient(client);
  };

  const renderPagination = (numPages: number) => (
    <Pagination numPages={numPages} currentPage={page} handleChange={setPage} />
  );

  return (
    <div>
      <H1>Pngme enhanced data</H1>

      <div className="mt-4">
        {activeClient && activeClient.type === 'demo' ? (
          <div>
            <p>
              Get your{' '}
              <span className="font-bold">
                <Link primary to="/organization">
                  API key
                </Link>
              </span>{' '}
              to access your data using Pngme's{' '}
              <ExternalLink
                secondary
                href="https://developers.api.pngme.com/reference/"
              >
                REST API
              </ExternalLink>
              . To add your own data,{' '}
              <Link primary to="/">
                create a new organization
              </Link>
              .
            </p>
          </div>
        ) : (
          <p>
            These users were added by the{' '}
            <span className="font-bold text-primary-500">
              {activeClient?.organizationName}
            </span>{' '}
            organization using the{' '}
            <span className="font-bold text-secondary-500">
              {activeClient?.type}
            </span>{' '}
            SDK client.
          </p>
        )}
      </div>

      <div className="mt-8 flex items-center justify-between gap-4">
        <div className="max-w-xs grow">
          <Input
            id="search"
            placeholder="Search"
            value={search}
            onChange={(event) => {
              setPage(1);
              setSearch(event.target.value);
            }}
          />
        </div>
        <div className="w-auto max-w-xs">
          <Select
            id="environment"
            value={activeClient?.type}
            onChange={(event) => handleChangeClientType(event.target.value)}
          >
            {clientTypeOptions.map((clientType) => (
              <option key={clientType} value={clientType}>
                {clientType}
              </option>
            ))}
          </Select>
        </div>
      </div>

      <Suspense fallback={<LoadingSpinner />}>
        <UserList
          search={debouncedSearch}
          page={page}
          renderPagination={renderPagination}
        />
      </Suspense>
    </div>
  );
};

export default UsersPage;

import { InputHTMLAttributes } from 'react';

import classNames from '../lib/classNames';

export type Props = {
  error?: boolean;
  truncate?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

const Input = ({ error, truncate, ...props }: Props) => (
  <input
    className={classNames(
      error ? 'border-red-400' : 'border-gray-300',
      truncate && 'truncate',
      'w-full rounded-lg border px-4 py-2 text-sm outline-none',
      'focus:border-secondary-500 focus:ring-1 focus:ring-secondary-500',
      'disabled:bg-gray-100',
    )}
    {...props}
  />
);

export default Input;

import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import {
  ClientTokenClaims,
  ConsoleUserTokenClaims,
  decodeToken,
} from '../lib/auth';
import { getLeadsCount } from '../lib/leads';
import useConsoleUserToken from './useConsoleUserToken';

const useLeadsCount = (
  filters: { field: string; operation: string; value: number }[],
) => {
  const { token } = useConsoleUserToken();
  const path = '/kenya/leads/count';

  const cacheKey = useMemo(() => {
    const { sub, subType } = decodeToken<
      ConsoleUserTokenClaims | ClientTokenClaims
    >(token);

    return [path, sub, subType, filters];
  }, [path, token, filters]);

  const fetcher = useCallback(() => getLeadsCount(filters), [filters]);

  const {
    data: leadsCount,
    error,
    isRefetching: isRefetchingLeadsCount,
  } = useQuery(cacheKey, fetcher, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  if (error !== null) {
    throw error;
  }

  return { isRefetchingLeadsCount, leadsCount };
};

export default useLeadsCount;

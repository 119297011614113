import classNames from '../lib/classNames';

const colorOf = {
  demo: 'bg-gray-100 text-gray-700',
  production: 'bg-primary-100 text-primary-700',
  test: 'bg-secondary-100 text-secondary-700',
};

type Props = { clientType: 'demo' | 'test' | 'production' };

const ClientTypePill = ({ clientType }: Props) => (
  <span
    className={classNames(
      'rounded-full px-2 py-1 text-xs',
      colorOf[clientType],
    )}
  >
    {clientType}
  </span>
);

export default ClientTypePill;

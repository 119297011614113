import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

import classNames from '../../lib/classNames';
import Button from '../Button';
import { PaginationBlock } from './PaginationBlock';

type PaginationProps = {
  numPages: number;
  currentPage: number;
  handleChange: (selected: number) => void;
};

export const Pagination = ({
  numPages,
  currentPage,
  handleChange,
}: PaginationProps) => {
  const firstBlockMaxPages = 8;
  const offset = 2;
  const middleBlockLength = 5;
  const defaultMax = 10;

  let startPaginationBlock: number[] = [];
  let middlePaginationBlock: number[] = [];
  let endPaginationBlock: number[] = [];

  startPaginationBlock = Array.from({ length: offset }, (_, i) => i + 1);

  if (
    currentPage <= firstBlockMaxPages - offset ||
    numPages <= defaultMax ||
    currentPage >= numPages - offset
  ) {
    startPaginationBlock = Array.from(
      { length: Math.min(firstBlockMaxPages, numPages) },
      (_, i) => i + 1,
    );
  }

  if (
    currentPage > firstBlockMaxPages - offset &&
    numPages > defaultMax &&
    currentPage < numPages - offset
  ) {
    middlePaginationBlock = Array.from(
      { length: middleBlockLength },
      (_, i) => currentPage + i - offset,
    );
  }

  if (numPages > firstBlockMaxPages) {
    endPaginationBlock = Array.from(
      { length: Math.min(offset, numPages - firstBlockMaxPages) },
      (_, i) => numPages - i,
    ).reverse();
  }

  if (
    currentPage > firstBlockMaxPages &&
    currentPage > Math.max(...middlePaginationBlock) &&
    currentPage < Math.min(...endPaginationBlock)
  ) {
    endPaginationBlock.unshift(currentPage);
  }

  return (
    <>
      <Button
        transparent
        disabled={currentPage === 1}
        onClick={() => handleChange(currentPage - 1)}
      >
        <div className="flex items-center text-center">
          <ChevronLeftIcon
            className={classNames(
              currentPage !== 1 && 'hover:text-primary-500',
              currentPage === 1 && 'text-gray-400',
              'h-5 w-5',
            )}
          />
          Previous
        </div>
      </Button>
      <div className="mx-5 flex">
        <PaginationBlock
          pageNumbers={startPaginationBlock}
          onClick={handleChange}
          currentPage={currentPage}
        />
      </div>
      {middlePaginationBlock.length > 0 && <div>...</div>}
      <div className="mx-5 flex">
        <PaginationBlock
          pageNumbers={middlePaginationBlock}
          onClick={handleChange}
          currentPage={currentPage}
        />
      </div>
      {endPaginationBlock.length > 0 && <div>...</div>}
      <div className="mx-5 flex">
        <PaginationBlock
          pageNumbers={endPaginationBlock}
          onClick={handleChange}
          currentPage={currentPage}
        />
      </div>
      <Button
        transparent
        disabled={currentPage === numPages}
        onClick={() => handleChange(currentPage + 1)}
      >
        <div className="flex items-center text-center">
          Next
          <ChevronRightIcon
            className={classNames(
              currentPage !== numPages && 'hover:text-primary-500',
              currentPage === numPages && 'text-gray-400',
              'h-5 w-5',
            )}
          />
        </div>
      </Button>
    </>
  );
};

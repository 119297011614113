import H1 from '../../components/H1';
import setTitle from '../../lib/setTitle';
import UndrawNotFound from '../../static/img/undraw_warning_secondary.svg';

const ErrorPage = () => {
  setTitle('Error');

  return (
    <div className="min-h-screen w-full">
      <div className="flex justify-center">
        <div>
          <div className="flex justify-center">
            <img
              src={UndrawNotFound}
              className="h-44"
              alt="404 over mountains"
            />
          </div>
          <div className="my-12 text-center">
            <H1>Something went wrong.</H1>
            <p className="mt-4">Our team has been notified.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;

/** Currency string formatting mapper. */
export const SYMBOL_BY_ABBREVIATION = {
  AED: 'د.إ', // United Arab Emirates Dirham
  GHS: 'GH₵', // Ghanaian Cedi
  KES: '/-', // Kenyan Shilling
  NGN: '₦', // Nigerian Naira
  USD: '$', // United States Dollar
  unknown: '',
};

export type Currency = 'KES' | 'GHS' | 'USD' | 'NGN' | 'AED' | null | 'unknown';

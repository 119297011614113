import useClients from '../../hooks/useClients';
import useFeatures from '../../hooks/useFeatures';
import useIncome from '../../hooks/useIncome';
import useOrganization from '../../hooks/useOrganization';
import useRiskScore from '../../hooks/useRiskScore';

type Props = { userId: string };

const UserFinancialInsights = ({ userId }: Props) => {
  const { activeClient } = useClients();
  const { organization } = useOrganization(activeClient.organizationId);
  const { incomeKesPerYear } = useIncome(userId);
  const { riskScore } = useRiskScore(userId, organization.country);
  const {
    countBettingAndLottery,
    sumOfCredits,
    sumOfDebits,
    sumOfLoanRepayments,
  } = useFeatures(userId);

  return (
    <div className="mt-2 space-y-1">
      <div>
        Income Per Year: {incomeKesPerYear !== null ? 'KES' : '-'}{' '}
        {incomeKesPerYear?.toLocaleString()}
      </div>
      <div>Risk Score: {riskScore !== null ? riskScore : '-'}</div>
      <div>
        Count of Betting and Lottery Events:{' '}
        {countBettingAndLottery !== null ? countBettingAndLottery : '-'}
      </div>
      <div>
        Monthly Credits: {organization.country === 'nigeria' ? 'NGN' : 'KES'}{' '}
        {sumOfCredits !== null ? (sumOfCredits / 3).toLocaleString() : '-'}
      </div>
      <div>
        Monthly Debits: {organization.country === 'nigeria' ? 'NGN' : 'KES'}{' '}
        {sumOfDebits !== null ? (sumOfDebits / 3).toLocaleString() : '-'}
      </div>
      <div>
        Monthly Loan Repayments:{' '}
        {organization.country === 'nigeria' ? 'NGN' : 'KES'}{' '}
        {sumOfLoanRepayments !== null
          ? (sumOfLoanRepayments / 3).toLocaleString()
          : '-'}
      </div>
    </div>
  );
};

export default UserFinancialInsights;

import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import { getWithToken } from '../lib/api';
import {
  ClientTokenClaims,
  ConsoleUserTokenClaims,
  decodeToken,
} from '../lib/auth';
import useClientToken from './useClientToken';

export type Response = {
  riskScore: number | null;
};

const useRiskScore = (userId: string, country: string) => {
  const { token } = useClientToken();
  const countryParam = country || 'kenya';
  const path = `/beta/${countryParam}/risk_score?user_uuid=${userId}`;

  const cacheKey = useMemo(() => {
    const { sub, subType } = decodeToken<
      ConsoleUserTokenClaims | ClientTokenClaims
    >(token);

    return [path, sub, subType];
  }, [path, token]);

  const fetcher = useCallback(
    () => getWithToken<Response>(path, token),
    [path, token],
  );

  // Not enabling `suspense` as a work around for handling runtime errors
  const { data } = useQuery(cacheKey, fetcher);

  return {
    riskScore: data?.riskScore || null,
  };
};

export default useRiskScore;

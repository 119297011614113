import useClients from '../../hooks/useClients';
import useOrganization from '../../hooks/useOrganization';
import setTitle from '../../lib/setTitle';
import FeatureNotEnabledContainer from './FeatureNotEnabledContainer';
import LeadManagementContainer from './LeadManagementContainer';

const LeadManagementPage = () => {
  setTitle('Lead Management');

  const { activeClient } = useClients();
  const organizationId = activeClient.organizationId;
  const {
    organization: { isLeadManagementEnabled },
  } = useOrganization(organizationId);

  if (!isLeadManagementEnabled) {
    return <FeatureNotEnabledContainer />;
  }

  return <LeadManagementContainer />;
};

export default LeadManagementPage;

import { Client } from '../lib/auth';
import { Currency } from '../lib/currency';
import { LedgerRecord } from '../lib/types';
import { ISODateString } from '../lib/types';
import useApiCache from './useApiCache';
import useConsoleUserToken from './useConsoleUserToken';

type Response = {
  records: LedgerRecord[];
};

export type TransactionRecord = {
  id: string;
  timestamp: ISODateString;
  institutionId: string;
  amount: number;
  currency: Currency;
  impact: string;
  labels: string[];
};

const isTransactionRecord = (
  record: LedgerRecord | TransactionRecord,
): record is TransactionRecord => {
  return record.amount !== null && record.impact !== null;
};

const getSortedTransactionsFromLedger = (
  ledgerRecords: LedgerRecord[],
): TransactionRecord[] => {
  const transactions = ledgerRecords.filter(
    isTransactionRecord,
  ) as TransactionRecord[];

  const sortedTransactions = transactions.sort((a, b) =>
    a.timestamp < b.timestamp ? 1 : -1,
  );

  return sortedTransactions;
};

const useUserInstitutionTransactions = (
  userId: string,
  institutionId: string,
  page: number,
  client: Client,
) => {
  // Number of records to display on the dashboard, per page
  const pageSize = 50;
  const { token } = useConsoleUserToken();
  const path = `/beta/dashboard/organizations/${client.organizationId}/ledger?user_uuid=${userId}&account_type=${client.type}`;
  const { data, error } = useApiCache<Response>(path, token);
  const transactions = getSortedTransactionsFromLedger(data.records);
  const filteredTransactionsByInstitution = transactions.filter(
    (transaction) => {
      return transaction.institutionId === institutionId;
    },
  );
  const numPages = Math.ceil(
    filteredTransactionsByInstitution.length / pageSize,
  );
  const paginatedTransactions = filteredTransactionsByInstitution.slice(
    pageSize * (page - 1),
    pageSize * page,
  );

  return { error, numPages, transactions: paginatedTransactions };
};

export default useUserInstitutionTransactions;

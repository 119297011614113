import dayjs from 'dayjs';

import Pill from '../../components/Pill';
import { TransactionRecord } from '../../hooks/useUserInstitutionTransactions';
import classNames from '../../lib/classNames';
import { SYMBOL_BY_ABBREVIATION } from '../../lib/currency';

type Props = {
  transactions: TransactionRecord[];
};

const TransactionList = ({ transactions }: Props) => (
  <ol className="divide-y divide-secondary-50">
    {transactions.map((record, index) => (
      <li key={index} className="py-3">
        <div className="flex items-center justify-between gap-8">
          <div className="space-y-1">
            <div className="text-xs font-medium">
              {dayjs(record.timestamp).format('LL')}
            </div>

            <ul className="flex gap-2">
              {record.labels.map((label) => (
                <li key={label}>
                  <Pill secondary>{label}</Pill>
                </li>
              ))}
            </ul>
          </div>

          <div
            className={classNames(
              'whitespace-nowrap text-right',
              record.impact.toLowerCase() === 'credit' && 'text-green-600',
            )}
          >
            <div className="font-extrabold">
              {record.impact.toLowerCase() === 'debit' ? '-' : ''}{' '}
              {record.amount}{' '}
              {record.currency
                ? SYMBOL_BY_ABBREVIATION[record.currency]
                : SYMBOL_BY_ABBREVIATION['unknown']}
            </div>

            <div className="text-xs capitalize">
              {record.impact.toLowerCase()}
            </div>
          </div>
        </div>
      </li>
    ))}
  </ol>
);

export default TransactionList;

import { ReactElement } from 'react';

import useUsers from '../../hooks/useUsers';
import UndrawNotFound from '../../static/img/undraw_not_found_primary.svg';
import UndrawWelcomeCats from '../../static/img/undraw_welcome_cats_primary.svg';
import UserListItem from './UserListItem';

type Props = {
  search: string;
  page: number;
  renderPagination: (numPages: number) => ReactElement;
};

const UserList = ({ search, renderPagination, page }: Props) => {
  const { users, numPages } = useUsers(search, page.toString());
  if (users.length > 0) {
    return (
      <div className="overflow-x-auto">
        <div className="mt-8 min-w-224">
          <div className="grid grid-cols-12 gap-x-4 px-4 py-3 font-bold">
            <div className="col-span-3">Name</div>
            <div className="col-span-3">Phone</div>
            <div className="col-span-3">External ID</div>
            <div className="col-span-2">Created</div>
          </div>

          <div className="overflow-hidden whitespace-nowrap">
            <ul className="divide-y divide-gray-200">
              {users.map((user) => (
                <UserListItem key={user.uuid} user={user} />
              ))}
            </ul>
          </div>
          <div className="mt-5 flex items-center justify-center">
            {renderPagination(numPages)}
          </div>
        </div>
      </div>
    );
  }

  if (search.length !== 0) {
    return (
      <div className="my-20 flex flex-col items-center">
        <img src={UndrawNotFound} className="h-32" alt="404 over mountains" />
        <p className="mt-8 text-sm">
          No users found. Try searching for something else.
        </p>
      </div>
    );
  } else {
    return (
      <div className="my-20 flex flex-col items-center">
        <img
          src={UndrawWelcomeCats}
          className="h-32"
          alt="404 over mountains"
        />
        <p className="mt-8 text-sm">
          No users found. Try a different client or organization.
        </p>
      </div>
    );
  }
};

export default UserList;

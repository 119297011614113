import useApiCache from './useApiCache';
import useClientToken from './useClientToken';

export type User = {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  primaryPhoneImei: string | null;
  deviceId: string;
  externalId: string;
  createdAt: Date;
  updatedAt: Date;
};

type Response = {
  page: number;
  numPages: number;
  users: User[];
};

const useUsers = (search?: string, page = '1') => {
  const { token } = useClientToken();
  const searchParams = new URLSearchParams({ page, search: search || '' });
  const path = '/beta/users?' + searchParams.toString();
  const { data, error } = useApiCache<Response>(path, token);
  return {
    error,
    numPages: data.numPages,
    users: data?.users,
  };
};

export default useUsers;

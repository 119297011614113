import useApiCache from './useApiCache';
import useConsoleUserToken from './useConsoleUserToken';

type Usage = {
  month: string;
  activePhoneUsers: number;
  apiDecisionRequests: number;
  apiFeaturesRequests: number;
  apiIncomeRequests: number;
  apiLedgerRequests: number;
  apiRiskScoreRequests: number;
  apiUsersRequests: number;
};

type Response = {
  usage: Usage[];
};

const PNGMEDEMO_ORGANIZATION_UUID = '047be82e-c517-40dc-95c7-d086a3bb1ad9';

export const useBilling = (organizationId: string) => {
  const isDemoOrganization = organizationId === PNGMEDEMO_ORGANIZATION_UUID;
  if (isDemoOrganization) {
    throw Error('Hook cannot be called when demo organization is active');
  }
  const { token } = useConsoleUserToken();
  const path = `/beta/dashboard/organizations/${organizationId}/billing`;
  const { data, error, refresh } = useApiCache<Response>(path, token);
  return { billing: data, error, refreshBilling: refresh };
};

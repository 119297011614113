/**
 * This module exposes organization member administration services.
 */

import { deleteWithToken, postWithToken } from './api';
import { getConsoleUserToken } from './auth';

/** Create a new API token granting access to an organization's data. */
export const createAPIToken = async (
  label: string,
  environment: string,
): Promise<string> => {
  const { token } = await postWithToken<{ token: string }>(
    `/beta/auth/tokens`,
    { environment, label },
    getConsoleUserToken(),
  );
  return token;
};

/** Delete an API token so it can no longer access an organization's data. */
export const revokeAPIToken = async (tokenId: string) => {
  await deleteWithToken(
    `/beta/auth/tokens`,
    { id: tokenId },
    getConsoleUserToken(),
  );
};

import dayjs from 'dayjs';

import H1 from '../../components/H1';
import { User } from '../../hooks/useUsers';

type Props = { user: User };

const UserProfile = ({ user }: Props) => {
  return (
    <div className="mt-8 space-y-1">
      <div>
        <H1>{user.firstName + ' ' + user.lastName}</H1>
      </div>
      <div className="text-xs text-gray-400">{user.uuid}</div>
      <div>{user.email}</div>
      <div className="flex">{user.phoneNumber}</div>
      <div className="flex">External ID: {user.externalId}</div>
      <div>Joined Pngme on {dayjs(user.createdAt).format('LL')}</div>
    </div>
  );
};

export default UserProfile;

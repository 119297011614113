import { Client } from '../lib/auth';
import { LedgerRecord } from '../lib/types';
import { ISODateString } from '../lib/types';
import useApiCache from './useApiCache';
import useConsoleUserToken from './useConsoleUserToken';

type Response = {
  records: LedgerRecord[];
};

export type AlertRecord = {
  id: string;
  timestamp: ISODateString;
  institutionId: string;
  labels: string[];
};

const getSortedAlertsFromLedger = (
  ledgerRecords: LedgerRecord[],
): AlertRecord[] => {
  const alerts = ledgerRecords.filter((record) => {
    return record.labels.length > 0;
  });

  const sortedAlerts = alerts.sort((a, b) =>
    a.institutionId > b.institutionId
      ? 1
      : b.institutionId > a.institutionId
        ? -1
        : 0,
  );

  return sortedAlerts;
};

const useUserInstitutionAlerts = (
  userId: string,
  institutionId: string,
  client: Client,
) => {
  const { token } = useConsoleUserToken();
  const path = `/beta/dashboard/organizations/${client.organizationId}/ledger?user_uuid=${userId}&account_type=${client.type}`;
  const { data, error } = useApiCache<Response>(path, token);
  const alerts = getSortedAlertsFromLedger(data.records);
  const filteredAlertsByInstitution = alerts.filter((alert) => {
    return alert.institutionId === institutionId;
  });

  return { alerts: filteredAlertsByInstitution, error };
};

export default useUserInstitutionAlerts;

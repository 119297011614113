/**
 * This module exports request primitives using the fetch API to provide a
 * consistent JSON interface for authenticated and unauthenticated requests
 * and responses.
 */

import camelcaseKeys from 'camelcase-keys';

export const API_BASE_URL = process.env.REACT_APP_PNGME_API_URL;
if (API_BASE_URL === undefined) {
  throw Error('REACT_APP_PNGME_API_URL not found.');
}

/** Thrown when 2xx status code is not returned for a request. */
export class FetchError extends Error {
  status?: number;
}

/** Call fetch asynchronously and parse the response body as JSON. */
const fetchJson = async <T>(
  ...[input, init]: [string, RequestInit | undefined]
): Promise<T> => {
  const headers = {
    'Content-Type': 'application/json',
    ...init?.headers,
  };

  const response = await fetch(input, { ...init, headers });

  if (!response.ok) {
    const error = new FetchError(await response.text());
    error.status = response.status;
    throw error;
  }

  const body = await response.json().catch(() => ({}));
  return camelcaseKeys(body, { deep: true });
};

/** Execute a GET request and return the parsed JSON response. */
export const get = async <T>(
  path: string,
  headers?: Record<string, string>,
) => {
  const url = new URL(path, API_BASE_URL);
  return await fetchJson<T>(url.href, { headers });
};

/** Execute an authenticated GET request and return the parsed JSON response. */
export const getWithToken = async <T>(
  path: string,
  token: string,
  headers?: Record<string, string>,
) => {
  const url = new URL(path, API_BASE_URL);
  headers = {
    ...headers,
    Authorization: `Bearer ${token}`,
  };
  return await get<T>(url.href, headers);
};

/** Execute a POST request and return the parsed JSON response. */
export const post = async <T>(
  path: string,
  body: Record<string, any>,
  headers?: Record<string, string>,
) => {
  const url = new URL(path, API_BASE_URL);
  return await fetchJson<T>(url.href, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
  });
};

/** Execute an authenticated POST request and return the parsed JSON response. */
export const postWithToken = async <T>(
  path: string,
  body: Record<string, any>,
  token: string,
  headers?: Record<string, string>,
) => {
  const url = new URL(path, API_BASE_URL);
  headers = {
    ...headers,
    Authorization: `Bearer ${token}`,
  };
  return await fetchJson<T>(url.href, {
    body: JSON.stringify(body),
    headers,
    method: 'POST',
  });
};

/** Execute a PUT request and return the parsed JSON response. */
export const put = async <T>(
  path: string,
  body: Record<string, any>,
  headers?: Record<string, string>,
) => {
  const url = new URL(path, API_BASE_URL);
  return await fetchJson<T>(url.href, {
    body: JSON.stringify(body),
    headers,
    method: 'PUT',
  });
};

/** Execute an authenticated PUT request and return the parsed JSON response. */
export const putWithToken = async <T>(
  path: string,
  body: Record<string, any>,
  token: string,
  headers?: Record<string, string>,
) => {
  const url = new URL(path, API_BASE_URL);
  headers = {
    ...headers,
    Authorization: `Bearer ${token}`,
  };
  return await fetchJson<T>(url.href, {
    body: JSON.stringify(body),
    headers,
    method: 'PUT',
  });
};

/** Execute a DELETE request and return the parsed JSON response. */
export const deleteWithToken = async <T>(
  path: string,
  body: Record<string, any>,
  token: string,
  headers?: Record<string, string>,
) => {
  const url = new URL(path, API_BASE_URL);
  headers = {
    ...headers,
    Authorization: `Bearer ${token}`,
  };
  return await fetchJson<T>(url.href, {
    body: JSON.stringify(body),
    headers,
    method: 'DELETE',
  });
};

/**
 * This module exposes authentication services to interact with the dashboard
 * user identity APIs.
 *
 * This is coupled to client/token persistence utilities in cache.ts.
 */
import { Buffer } from 'buffer';
import camelcaseKeys from 'camelcase-keys';

import { FetchError, get, getWithToken, post, put, putWithToken } from './api';
import * as cache from './cache';

export type ClientType = 'demo' | 'test' | 'production' | 'console_user';

export type Client = {
  organizationName: string;
  organizationId: string;
  apiToken: string;
  sdkToken: string | null;
  type: ClientType;
};

export type AuthResponse = {
  organizationName: string;
  organizationUuid: string;
  auth: {
    apiToken: string;
    sdkToken: string;
    type: ClientType;
  }[];
};

export type ConsoleUserTokenClaims = {
  sub: string;
  subType: 'console_user';
  exp: number;
  email: string;
  organizationUuid: string;
  organizationRole: 'owner' | 'admin' | 'member';
  clientUuid: string;
};

export type ClientTokenClaims = {
  sub: string;
  subType: 'client';
  scope: string;
  exp: number;
};

/**
 * Encode string as base64 using utf-8 character encoding.
 * This replaces the built-in btoa which returns a base64 string using utf-16 character encoding.
 * Encoding to utf-8 avoids character incompatibilities with our APIs.
 */
const toBase64 = (str: string): string => {
  return Buffer.from(str, 'utf-8').toString('base64');
};

const flattenClients = (authResponse: AuthResponse[]): Client[] => {
  return authResponse
    .map((organization) =>
      organization.auth.map((auth) => ({
        organizationId: organization.organizationUuid,
        organizationName: organization.organizationName,
        ...auth,
      })),
    )
    .flat();
};

/** Basic authentication using the dashboard user's email and password. */
export const login = async (email: string, password: string) => {
  const credentials = toBase64(`${email}:${password}`);
  const headers = { Authorization: `Basic ${credentials}` };
  const body = await get<AuthResponse[]>('/beta/auth', headers);
  return flattenClients(body);
};

/** Bearer authentication using the active client apiToken. */
export const loginWithToken = async () => {
  const token = getConsoleUserToken();
  const body = await getWithToken<AuthResponse[]>('/beta/auth', token);
  return flattenClients(body);
};

type SignupResponse = {
  userUuid: string;
  clientUuid: string;
};

export type SignupOptions = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  country: string;
  recaptchaResponse: string;
};

export type SignupResult = {
  isSuccess: boolean;
  isExistingEmail: boolean;
};

const isExistingEmailError = (error: unknown): error is FetchError => {
  return (
    error instanceof FetchError &&
    error.status === 400 &&
    error.message.includes('email already exists')
  );
};

/** Register a new dashboard user. */
export const signup = async ({
  email,
  password,
  firstName,
  lastName,
  country,
  phoneNumber,
  recaptchaResponse,
}: SignupOptions): Promise<SignupResult> => {
  const body = {
    email,
    first_name: firstName, // eslint-disable-line camelcase
    last_name: lastName, // eslint-disable-line camelcase
    location: country,
    password,
    phone_number: phoneNumber, // eslint-disable-line camelcase
    recaptcha_response: recaptchaResponse, // eslint-disable-line camelcase
  };
  try {
    await post<SignupResponse[]>('/beta/dashboard/signup', body);
  } catch (error: unknown) {
    if (isExistingEmailError(error)) {
      return { isExistingEmail: true, isSuccess: false };
    } else {
      throw error;
    }
  }
  return { isExistingEmail: false, isSuccess: true };
};

/** Send the dashboard user a password reset email. */
export const resetPassword = async (email: string) => {
  await post<null>('/beta/auth/password', {
    email,
  });
};

/** Assign the dashboard user a new password. */
export const updatePassword = async (
  email: string,
  confirmationCode: string,
  password: string,
) => {
  await put<null>('/beta/auth/password', {
    confirmation_code: confirmationCode, // eslint-disable-line camelcase
    email,
    password: toBase64(password),
  });
};

/** Update the dashboard user's profile. */
export const updateProfile = async (
  firstName: string,
  lastName: string,
  userUuid: string,
) => {
  const token = getConsoleUserToken();
  await putWithToken<null>(
    `/beta/dashboard/users/${userUuid}`,
    { first_name: firstName, last_name: lastName }, // eslint-disable-line camelcase
    token,
  );
};

/** List all unexpired authorized clients. */
export const getAuthorizedClients = () => {
  const clients = cache.getAuthorizedClients();
  const unexpiredClients = clients.reduce<Client[]>((memo, client) => {
    const { apiToken } = client;
    const token = decodeToken<ClientTokenClaims | ConsoleUserTokenClaims>(
      apiToken,
    );
    const expiresAt = new Date(token.exp * 1000);
    const isExpired = new Date() > expiresAt;
    if (isExpired) {
      return memo;
    } else {
      return [...memo, client];
    }
  }, []);
  return unexpiredClients;
};

/** Find the active authorized client or fallback to the demo client. */
export const getActiveClient = (clientType?: ClientType) => {
  const clients = getAuthorizedClients();
  const clientId = cache.getActiveClientId();

  if (!clients.length) {
    // User is not authenticated
    return null;
  }

  if (clientId !== null) {
    // User has previously activated a client.
    const activeClient = clients.find(
      (client) =>
        client.organizationId === clientId.organizationId &&
        client.type === (clientType || clientId.type),
    );

    if (activeClient !== undefined) {
      return activeClient;
    }
  }

  // Fallback to demo client if user has not previously selected a client or the
  // user is not authorized to access the previously active client.
  const demoClient = clients.find((client) => client.type === 'demo');
  if (demoClient === undefined) {
    throw Error('User not authorized to access demo client.');
  }
  return demoClient;
};

/** Find console user uuid by parsing available clients.
 *
 * This needs to be explicitly given a list of clients since use cases cannot
 * wait for the react render cycle to update the cached clients.
 */
export const getConsoleUserUuid = (clients: Client[]): string => {
  if (!clients.length) {
    throw Error('user is not authenticated');
  }

  const consoleUserClient = clients.find(
    (client) => client.type === 'console_user',
  );
  if (consoleUserClient === undefined) {
    throw Error('console_user client not found');
  }

  const claims: ConsoleUserTokenClaims = decodeToken(
    consoleUserClient.apiToken,
  );
  return claims.sub;
};

/** Get console_user subject access token for the active organization. */
export const getConsoleUserToken = () => {
  const client = getActiveClient('console_user');
  const token = client?.apiToken;
  if (!token) {
    throw Error('user is not authenticated');
  }
  return token;
};

/** Get client subject access token for the active organization. */
export const getClientProductionToken = () => {
  const client = getActiveClient('production');
  const token = client?.apiToken;
  if (!token) {
    throw Error('user is not authenticated');
  }
  return token;
};

/** Decode JWT payload but does not perform signature verification. */
export const decodeToken = <T>(token: string): T => {
  const [, payload] = token.split('.');
  return camelcaseKeys(JSON.parse(atob(payload)));
};

import { useMemo } from 'react';

import { ConsoleUserTokenClaims, decodeToken } from '../lib/auth';
import useClients from './useClients';

const useConsoleUserToken = () => {
  const { activeClient, authorizedClients } = useClients();
  const organizationId = activeClient.organizationId;
  const consoleUserClient = authorizedClients.find(
    (client) =>
      client.organizationId === organizationId &&
      client.type === 'console_user',
  );

  if (consoleUserClient === undefined) {
    throw new Error(
      'No token found with console_user authorization subject type',
    );
  }

  const token = consoleUserClient.apiToken;
  const claims = useMemo(
    () => decodeToken<ConsoleUserTokenClaims>(token),
    [token],
  );

  return { claims, token };
};

export default useConsoleUserToken;

import { Client } from '../lib/auth';
import { LedgerRecord } from '../lib/types';
import useApiCache from './useApiCache';
import useConsoleUserToken from './useConsoleUserToken';

type InstitutionIds = string[];

type Response = {
  records: LedgerRecord[];
};

const getInstitutionsFromLedger = (
  ledgerRecords: LedgerRecord[],
): InstitutionIds => {
  const institutions = new Set(
    ledgerRecords.map((record) => record.institutionId),
  );
  return Array.from(institutions).sort();
};

const useUserInstitutions = (userId: string, client: Client) => {
  const { token } = useConsoleUserToken();
  const path = `/beta/dashboard/organizations/${client.organizationId}/ledger?user_uuid=${userId}&account_type=${client.type}`;
  const { data, error } = useApiCache<Response>(path, token);
  const institutions = getInstitutionsFromLedger(data.records);

  return { error, institutions };
};

export default useUserInstitutions;

import { useMemo } from 'react';

import { ClientTokenClaims, decodeToken } from '../lib/auth';
import useClients from './useClients';

const useClientToken = () => {
  const { activeClient } = useClients();
  const token = activeClient.apiToken;
  const claims = useMemo(() => decodeToken<ClientTokenClaims>(token), [token]);
  return { claims, token };
};

export default useClientToken;

import { ButtonHTMLAttributes } from 'react';

import classNames from '../lib/classNames';

type Props = {
  primary?: boolean;
  secondary?: boolean;
  transparent?: boolean;
  wide?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({ primary, secondary, transparent, wide, ...props }: Props) => {
  var colors;
  if (primary) {
    colors = classNames(
      'bg-primary-600 hover:bg-primary-700 text-white',
      'disabled:bg-primary-200 disabled:text-primary-500',
    );
  } else if (secondary) {
    colors = classNames(
      'bg-secondary-500 hover:bg-secondary-600 text-white',
      'disabled:bg-secondary-200 disabled:text-secondary-500',
    );
  } else if (transparent) {
    colors = classNames('hover:text-primary-500', 'disabled:text-gray-400');
  } else {
    colors = classNames(
      'bg-gray-800 hover:bg-gray-900 text-white',
      'disabled:bg-gray-200 disabled:text-gray-500',
    );
  }

  return (
    <button
      className={classNames(
        colors,
        wide && 'w-full',
        'rounded-lg px-4 py-2 text-sm',
      )}
      {...props}
    />
  );
};

export default Button;

/** Check whether the email is valid. */
const email = (value: string) => {
  if (!value) return false;

  const regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regexp.test(value);
};

/**
 * Check whether the password has 8 characters, at least one capital letter,
 * lower case letter, number, and special character.
 */
const password = (value: string) => {
  const regex =
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\^$*.{}[\]?"!@#%&/,<>':;|_`()\\~])/;
  return value.length >= 8 && regex.test(value);
};

/**
 * Check whether the phone number has all numeric characters
 * and has 8 to 14 characters in length (accounting for with/
 * without country code)
 */
const phoneNumber = (value: string) => {
  const sanitizedPhoneNumber = value.replace(/\s/g, '');
  const regex = /^\+?[1-9]\d{8,14}$/;
  return regex.test(sanitizedPhoneNumber);
};

/**
 * Check whether the kenyaNationalId has 9 numbers
 */
const kenyaNationalId = (value: string) => {
  const regex = /[0-9]/;
  return value.length === 9 && regex.test(value);
};

/**
 * This module exports validation utilities to check if the user has supplied
 * email and password inputs that meet our criteria. This helps with form input
 * feedback for users but does not replace server side validation.
 */
const validate = { email, kenyaNationalId, password, phoneNumber };

export default validate;

import { FormEvent, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import H1 from '../../components/H1';
import LabeledInput from '../../components/LabeledInput';
import Link from '../../components/Link';
import useAuth from '../../hooks/useAuth';
import useClients from '../../hooks/useClients';
import { createOrganization } from '../../lib/organizations';
import setTitle from '../../lib/setTitle';
import { trackOrganizationCreated } from '../../lib/tracking';
import UndrawTeam from '../../static/img/undraw_team_primary.svg';

const OrganizationCreatePage = () => {
  setTitle('Create organization');

  const navigate = useNavigate();
  const { refreshLogin } = useAuth();
  const { setActiveClient } = useClients();

  const [isLoading, setIsLoading] = useState(false);
  const [organizationName, setOrganizationName] = useState('');

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoading(true);
    await toast
      .promise(
        createOrganization(organizationName).then(async (createdOrg) => {
          const clients = await refreshLogin();
          const createdProductionClient = clients.filter(
            (client) =>
              client.type === 'production' &&
              client.organizationId === createdOrg.organizationUuid,
          )[0];
          setActiveClient(createdProductionClient);
        }),
        {
          error: 'Failed to create organization',
          loading: 'Creating organization...',
          success: 'Created new organization',
        },
        { id: 'organization-create' },
      )
      .finally(() => setIsLoading(false));

    trackOrganizationCreated(organizationName);
    navigate('/dashboard');
  };

  return (
    <div className="flex justify-center">
      <div className="mt-12 w-full md:w-96">
        <div className="flex justify-center">
          <img src={UndrawTeam} className="mb-8 h-32" alt="Team of people" />
        </div>

        <H1>Create organization</H1>

        <p className="mt-2 text-sm">
          Start a new organization with unique API and SDK keys to access
          financial insights about your users. Free for your first 1,000 users.
        </p>

        <form className="mt-4 w-full space-y-4" onSubmit={handleFormSubmit}>
          <LabeledInput
            id="organizationName"
            label="Organization name"
            type="text"
            value={organizationName}
            onChange={(event) => setOrganizationName(event.target.value)}
          />

          <Button disabled={isLoading} wide>
            Create organization
          </Button>
        </form>

        <div className="mt-6 text-sm">
          Or{' '}
          <Link primary to="/">
            select an existing organization
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrganizationCreatePage;

import { FormEvent, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import Button from '../../components/Button';
import H2 from '../../components/H2';
import LabeledInput from '../../components/LabeledInput';
import Link from '../../components/Link';
import TextLogo from '../../components/TextLogo';
import { resetPassword } from '../../lib/auth';
import setTitle from '../../lib/setTitle';
import validate from '../../lib/validate';

const ForgotPasswordPage = () => {
  setTitle('Forgot password');
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const defaultEmail = searchParams.get('email') || '';
  const [email, setEmail] = useState(defaultEmail);

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const isFormValid = !isLoading && isEmailValid;

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isFormValid) {
      return;
    }

    setIsLoading(true);
    toast
      .promise(resetPassword(email), {
        error: 'Error resetting your password',
        loading: 'Sending password reset email...',
        success: 'Check your email inbox',
      })
      .then(() => {
        // Use search param to pass email to update password page rather than
        // navigate(..., {state: { email }}) to enable branded reset emails
        // where the user's email and confirmation code can be included in the
        // link they are sent.
        const emailSearchParams = new URLSearchParams({ email });
        const path = '/update-password?' + emailSearchParams.toString();
        navigate(path);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const emailAsQueryParameter = validate.email(email)
    ? `?email=${encodeURIComponent(email)}`
    : '';

  return (
    <div className="w-full md:w-96">
      <div className="flex justify-center py-12">
        <div className="cursor-default text-4xl">
          <TextLogo />
        </div>
      </div>

      <H2>Reset your password</H2>

      <p className="mt-2 text-sm">
        Enter the email address associated with your account and we'll send you
        a link to reset your password.
      </p>

      <form className="w-full space-y-4" onSubmit={handleFormSubmit}>
        <LabeledInput
          id="email"
          label="Email"
          type="text"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          onBlur={() => setIsEmailValid(validate.email(email))}
          error={!isEmailValid}
        />

        <Button disabled={isLoading} wide>
          Continue
        </Button>

        <div className="flex justify-center">
          <Link primary to={`/login${emailAsQueryParameter}`}>
            <div className="px-4 py-1 text-sm">Return to login</div>
          </Link>
        </div>
      </form>

      <div className="mt-6 text-sm">
        Don't have an account?{' '}
        <Link primary to="/signup">
          Sign up
        </Link>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;

import { ChevronRightIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';

import Link from '../../components/Link';
import { User } from '../../hooks/useUsers';

type Props = {
  user: User;
};

const UserListItem = ({ user }: Props) => (
  <li className="text-sm">
    <Link to={user.uuid}>
      <div className="my-2 rounded-lg px-4 py-4 hover:bg-secondary-50 hover:text-secondary-800">
        <div className="grid grid-cols-12 items-center gap-4">
          <div className="col-span-3 truncate font-medium">
            {user.firstName + ' ' + user.lastName}
          </div>
          <div className="col-span-3">{user.phoneNumber}</div>
          <div className="col-span-3 truncate">{user.externalId}</div>
          <div className="col-span-2">{dayjs(user.createdAt).fromNow()}</div>
          <div className="flex justify-end">
            <ChevronRightIcon className="h-4 w-4" />
          </div>
        </div>
      </div>
    </Link>
  </li>
);

export default UserListItem;

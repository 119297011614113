import { SelectHTMLAttributes } from 'react';

import classNames from '../lib/classNames';

type Props = {
  transparent?: boolean;
} & SelectHTMLAttributes<HTMLSelectElement>;
const Select = ({ transparent, ...props }: Props) => (
  <select
    className={classNames(
      transparent
        ? 'border-0 focus:ring-0'
        : 'border border-gray-300 focus:border-secondary-500 focus:ring-1 focus:ring-secondary-500',
      'w-full rounded-lg py-2 pl-4 pr-8 text-sm outline-none',
    )}
    {...props}
  />
);

export default Select;

import { ReactNode } from 'react';

import Button from '../../components/Button';
import H2 from '../../components/H2';
import Link from '../../components/Link';

type FeatureCardProps = {
  imageSrc: string;
  imageAlt: string;
  title: string;
  body: ReactNode;
  buttonText: string;
  buttonHref: string;
};

const FeatureCard = ({
  imageSrc,
  imageAlt,
  title,
  body,
  buttonHref,
  buttonText,
}: FeatureCardProps) => (
  <div className="flex flex-col">
    <img src={imageSrc} className="mb-6 h-24" alt={imageAlt} />
    <H2>{title}</H2>
    <p className="mt-2 mb-4">{body}</p>
    <div className="mt-auto">
      <Link to={buttonHref}>
        <Button wide>{buttonText}</Button>
      </Link>
    </div>
  </div>
);

export default FeatureCard;

import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { getWithToken } from '../lib/api';
import useClientToken from './useClientToken';

export type Response = {
  incomeKesPerYear: number | null;
};

const useIncome = (userId: string) => {
  const { token } = useClientToken();
  const path = `/beta/kenya/income?user_uuid=${userId}`;

  const fetcher = useCallback(
    () => getWithToken<Response>(path, token),
    [path, token],
  );

  // Not enabling `suspense` as a work around for handling runtime errors
  const { data } = useQuery([path, token], fetcher);

  return {
    incomeKesPerYear: data?.incomeKesPerYear || null,
  };
};

export default useIncome;

import { Navigate, Outlet } from 'react-router-dom';

import ErrorBoundary from '../components/ErrorBoundary';
import useAuth from '../hooks/useAuth';

const UnauthenticatedLayout = () => {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  return (
    <div className="p-6">
      <main>
        <div className="flex justify-center">
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </div>
      </main>
    </div>
  );
};

export default UnauthenticatedLayout;

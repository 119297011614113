import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { getWithToken } from '../lib/api';
import useClientToken from './useClientToken';

type Features = {
  countBettingAndLotteryEvents090: number | null;
  sumOfCredits090: number | null;
  sumOfDebits090: number | null;
  sumOfLoanRepayments090: number | null;
};

export type Response = {
  features: Features;
};

const featureNames = [
  'count_betting_and_lottery_events_0_90',
  'sum_of_credits_0_90',
  'sum_of_debits_0_90',
  'sum_of_loan_repayments_0_90',
];

const useFeatures = (userId: string) => {
  const { token } = useClientToken();
  const params = new URLSearchParams({
    features: featureNames.join(','),
    user_uuid: userId, // eslint-disable-line camelcase
  });
  const path = `/beta/features?` + params.toString();

  const fetcher = useCallback(
    () => getWithToken<Response>(path, token),
    [path, token],
  );

  // Not enabling `suspense` as a work around for handling runtime errors
  const { data } = useQuery([path, token], fetcher);

  return {
    countBettingAndLottery:
      data?.features.countBettingAndLotteryEvents090 || null,
    sumOfCredits: data?.features.sumOfCredits090 || null,
    sumOfDebits: data?.features.sumOfDebits090 || null,
    sumOfLoanRepayments: data?.features.sumOfLoanRepayments090 || null,
  };
};

export default useFeatures;

import { Menu, Transition } from '@headlessui/react';
import { Bars3Icon, ChevronDownIcon } from '@heroicons/react/20/solid';

import useAuth from '../hooks/useAuth';
import useClients from '../hooks/useClients';
import Link from './Link';
import NavLink from './NavLink';
import TextLogo from './TextLogo';

const desktopNavItems = [
  { label: 'Data', link: '/users' },
  { label: 'Leads', link: '/leads' },
  { label: 'Organization', link: '/organization' },
];

const dropdownNavItems = [
  { label: 'Dashboard', link: '/dashboard' },
  { label: 'Switch organization', link: '/' },
  { label: 'Keys', link: '/organization' },
  { label: 'Account', link: '/settings' },
];

const Navbar = () => {
  const { activeClient } = useClients();
  const { logout } = useAuth();
  return (
    <header>
      <div>
        <div className="py-6">
          <div className="text-sm leading-6">
            <div className="flex items-center justify-between">
              <div className="text-2xl sm:w-64">
                <Link to="/dashboard">
                  <TextLogo />
                </Link>
              </div>

              <nav className="flex items-center">
                <ul
                  aria-label="Desktop nav"
                  className="hidden gap-2 whitespace-nowrap font-medium sm:flex"
                >
                  {desktopNavItems.map((item) => (
                    <li key={item.link}>
                      <NavLink to={item.link}>{item.label}</NavLink>
                    </li>
                  ))}
                </ul>
              </nav>

              <div className="flex items-center justify-end sm:w-64">
                <Link to="/">
                  <div className="mx-2 hidden items-center whitespace-nowrap lg:flex">
                    {activeClient.organizationName}
                    <ChevronDownIcon className="mx-1 h-4 w-4" />
                  </div>
                </Link>

                <Menu
                  aria-label="Dropdown nav"
                  as="nav"
                  className="relative z-50"
                >
                  <Menu.Button className="flex items-center gap-2 px-4 py-2 font-medium hover:text-gray-800">
                    <div className="hidden sm:block">Settings</div>
                    <Bars3Icon className="h-4 w-4 text-gray-500" />
                  </Menu.Button>

                  <Transition
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0"
                    enterTo="transform opacity-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100"
                    leaveTo="transform opacity-0"
                  >
                    <Menu.Items className="absolute right-0 mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-gray-100">
                      {desktopNavItems.map((item) => (
                        <Link to={item.link} key={item.link}>
                          <Menu.Item>
                            <div className="py-2 px-4 hover:bg-gray-50 sm:hidden">
                              {item.label}
                            </div>
                          </Menu.Item>
                        </Link>
                      ))}

                      {dropdownNavItems.map((item) => (
                        <Link to={item.link} key={item.link}>
                          <Menu.Item>
                            <div className="py-2 px-4 hover:bg-gray-50">
                              {item.label}
                            </div>
                          </Menu.Item>
                        </Link>
                      ))}

                      <Menu.Item>
                        <button
                          onClick={() => logout()}
                          className="mt-1 w-full border-t border-gray-100 py-2 px-4 hover:text-gray-900"
                        >
                          Sign out
                        </button>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;

import { Suspense } from 'react';

import LoadingSpinner from '../../components/LoadingSpinner';
import useClients from '../../hooks/useClients';
import setTitle from '../../lib/setTitle';
import APITokensContainer from './APITokensContainer';
import DemoOrganizationSplash from './DemoOrganizationContainer';
import MembersContainer from './MembersContainer';
import SDKTokensContainer from './SDKTokensContainer';
import UsageContainer from './UsageContainer';

const OrganizationPage = () => {
  const { activeClient } = useClients();
  setTitle(activeClient.organizationName);

  if (activeClient.type === 'demo') {
    return <DemoOrganizationSplash />;
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <UsageContainer organizationId={activeClient.organizationId} />
      <APITokensContainer />
      <SDKTokensContainer />
      <MembersContainer
        organizationId={activeClient.organizationId}
        organizationName={activeClient.organizationName}
      />
    </Suspense>
  );
};

export default OrganizationPage;

import { Component } from 'react';

import withCurrentLocation from '../hoc/withCurrentLocation';
import ErrorPage from '../routes/ErrorPage';

type Props = {
  children: JSX.Element;
  currentLocation: string;
  fallback?: JSX.Element;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps: Props) {
    // Reset error state when user navigates to another page.
    if (prevProps.currentLocation !== this.props.currentLocation) {
      this.setState((prevState) => ({ ...prevState, hasError: false }));
    }
  }

  render() {
    if (this.state.hasError) {
      const fallback = this.props?.fallback || <ErrorPage />;
      return fallback;
    }

    return this.props.children;
  }
}

export default withCurrentLocation(ErrorBoundary);

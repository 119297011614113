import { useCallback, useContext, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { AuthContext } from '../context/AuthProvider';
import { FetchError } from '../lib/api';
import * as auth from '../lib/auth';
import { trackIdentity, trackSignup } from '../lib/tracking';

/** Exposes methods to manage authentication state.
 *
 * To access details about the currently active or authorized clients from
 * within an authorized session, see useClients.
 */
const useAuth = () => {
  const queryClient = useQueryClient();
  const { activeClient, authorizedClients, setAuthorizedClients } =
    useContext(AuthContext);

  const isAuthenticated = !!activeClient && authorizedClients.length > 0;

  const { data, error } = useQuery<auth.Client[], FetchError>(
    '/beta/auth',
    auth.loginWithToken,
    {
      enabled: isAuthenticated,
      refetchInterval: 3600 * 1000,
      refetchOnWindowFocus: false,
    },
  );

  const refreshLogin = useCallback(
    () => queryClient.fetchQuery<auth.Client[]>('/beta/auth'),
    [queryClient],
  );

  const login = useCallback(
    async (email: string, password: string) => {
      const clients = await auth.login(email, password);
      queryClient.setQueryData('/beta/auth', clients);
      setAuthorizedClients(clients);

      const consoleUserUuid = auth.getConsoleUserUuid(clients);
      trackIdentity(consoleUserUuid, email);
    },
    [setAuthorizedClients, queryClient],
  );

  const logout = useCallback(async () => {
    queryClient.cancelQueries();
    queryClient.clear();
    setAuthorizedClients([]);
  }, [setAuthorizedClients, queryClient]);

  const signup = useCallback(
    async (options: auth.SignupOptions) => {
      const signupResult = await auth.signup(options);
      if (signupResult.isSuccess) {
        trackSignup(options);
        await login(options.email, options.password);
      }
      return signupResult;
    },
    [login],
  );

  useEffect(() => {
    const isAuthorizationError =
      !!error?.status && [401, 403].includes(error.status);
    const isAuthRefreshSuccess = !!data && data.length > 0;
    if (isAuthorizationError) {
      console.error(error);
      logout();
    } else if (isAuthRefreshSuccess) {
      setAuthorizedClients(data);
    }
  }, [data, error, logout, setAuthorizedClients]);

  return {
    isAuthenticated,
    login,
    logout,
    refreshLogin,
    signup,
  };
};

export default useAuth;

import Input, { Props as InputProps } from './Input';
import Label from './Label';

type Props = {
  id: string;
  label: JSX.Element | string;
  subText?: JSX.Element | string;
} & InputProps;

const LabeledInput = ({ id, label, subText, ...props }: Props) => {
  return (
    <div className="space-y-2 py-3">
      <Label htmlFor={id}>{label}</Label>
      <Input id={id} {...props} />
      {subText && <div className="text-xs text-gray-500">{subText}</div>}
    </div>
  );
};

export default LabeledInput;

import { InputHTMLAttributes } from 'react';

import classNames from '../lib/classNames';

const Checkbox = (props: InputHTMLAttributes<HTMLInputElement>) => (
  <input
    type="checkbox"
    className={classNames(
      'appearance-none focus:ring-0',
      'rounded-md',
      'text-primary-500',
    )}
    {...props}
  />
);

export default Checkbox;

import useApiCache from './useApiCache';
import useConsoleUserToken from './useConsoleUserToken';

export type ExistingAPIToken = {
  id: string;
  token: string;
  label: string;
  environment: string;
  isInternal: boolean;
  createdAt: string;
  createdBy: string;
};

export type ListAPITokensDTO = {
  tokens: ExistingAPIToken[];
};

const useAPITokens = () => {
  const { token } = useConsoleUserToken();
  const path = '/beta/auth/tokens';
  const { data, error, refresh } = useApiCache<ListAPITokensDTO>(path, token);
  const apiTokens = data?.tokens;

  apiTokens.sort((a, b) => {
    return a.createdAt < b.createdAt ? -1 : 1;
  });

  return {
    apiTokens: data?.tokens,
    error,
    refreshAPITokens: refresh,
  };
};

export default useAPITokens;

import dayjs from 'dayjs';

import ExternalLink from '../../components/ExternalLink';
import H2 from '../../components/H2';
import { useBilling } from '../../hooks/useBilling';
type Props = { organizationId: string };

const UsageContainer = ({ organizationId }: Props) => {
  const { billing } = useBilling(organizationId);

  return (
    <div>
      <H2>Usage</H2>

      {billing.usage.length > 0 ? (
        <div className="text-sm">
          <table className="mt-4 divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="p-3 pl-2 text-center">Year</th>
                <th className="p-3 pl-2 text-center">Month</th>
                <th className="p-3 pl-2 text-center">Active mobile users</th>
                <th className="p-3 pl-2 text-center">Decision API requests</th>
                <th className="p-3 pl-2 text-center">Features API requests</th>
                <th className="p-3 pl-2 text-center">Income API requests</th>
                <th className="p-3 pl-2 text-center">Ledger API requests</th>
                <th className="p-3 pl-2 text-center">
                  Risk Score API requests
                </th>
                <th className="p-3 pl-2 text-center">Users API requests</th>
              </tr>
            </thead>

            <tbody>
              {billing.usage.map((usage, index) => (
                <tr key={usage.month}>
                  <td className="p-3 pl-2 text-center">
                    {index === 0 || dayjs(usage.month).month() === 11
                      ? dayjs(usage.month).format('YYYY')
                      : ''}
                  </td>
                  <td className="p-3 pl-2 text-center">
                    {dayjs(usage.month).format('MMMM')}
                  </td>
                  <td className="p-3 pl-2 text-center">
                    {usage.activePhoneUsers.toLocaleString()}
                  </td>
                  <td className="p-3 pl-2 text-center">
                    {usage.apiDecisionRequests.toLocaleString()}
                  </td>
                  <td className="p-3 pl-2 text-center">
                    {usage.apiFeaturesRequests.toLocaleString()}
                  </td>
                  <td className="p-3 pl-2 text-center">
                    {usage.apiIncomeRequests.toLocaleString()}
                  </td>
                  <td className="p-3 pl-2 text-center">
                    {usage.apiLedgerRequests.toLocaleString()}
                  </td>
                  <td className="p-3 pl-2 text-center">
                    {usage.apiRiskScoreRequests.toLocaleString()}
                  </td>
                  <td className="p-3 pl-2 text-center">
                    {usage.apiUsersRequests.toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className="mt-4 text-xs text-gray-500">
            Active mobile users sent financial data using Pngme's SDK during
            each month.
          </p>
        </div>
      ) : (
        <p className="mt-4 text-sm">
          No active users found. Go to the{' '}
          <ExternalLink secondary href="https://developers.api.pngme.com/docs">
            SDK Integration docs
          </ExternalLink>{' '}
          to learn how to integrate Pngme with your app.
        </p>
      )}
    </div>
  );
};

export default UsageContainer;

import { KeyIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { useState } from 'react';
import toast from 'react-hot-toast';

import Button from '../../components/Button';
import ExternalLink from '../../components/ExternalLink';
import H2 from '../../components/H2';
import Pill from '../../components/Pill';
import Select from '../../components/Select';
import useAPITokens from '../../hooks/useAPITokens';
import useConsoleUserToken from '../../hooks/useConsoleUserToken';
import { createAPIToken, revokeAPIToken } from '../../lib/apiTokens';
import { trackApiTokenCopied } from '../../lib/tracking';

const environments = ['production', 'test'] as const;
type Environment = (typeof environments)[number];

const APITokensContainer = () => {
  const { claims } = useConsoleUserToken();

  const [createTokenEnvironment, setCreateTokenEnvironment] =
    useState<Environment>('production');

  const { apiTokens, refreshAPITokens } = useAPITokens();

  const handleCreateAPIToken = async (label: string) => {
    // Avoiding toast promise for control over each state.
    const toastId = 'api-token-create';
    toast.loading('Creating token', { id: toastId });
    try {
      const token = await createAPIToken(label, createTokenEnvironment);
      toast.custom(
        () => (
          <div className="bg-gray-900 text-gray-50 text-sm shadow-lg rounded-lg flex transition">
            <div className="flex-1 p-4">
              <div className="flex place-items-center">
                <KeyIcon className="text-green-500 w-6 h-6" />

                <div className="mx-3 space-y-0.5 max-w-full">
                  <p className="font-bold flex-grow">API token created!</p>

                  <p>
                    Copy this token now, you will not be able to access it in
                    the future.
                  </p>

                  <p className="text-xs text-gray-500 truncate">{token}</p>

                  <button
                    onClick={() =>
                      navigator.clipboard
                        .writeText(token)
                        .then(() => trackApiTokenCopied(createTokenEnvironment))
                    }
                    className="flex items-center justify-center font-medium text-secondary-400 hover:text-secondary-300 focus:text-green-500 transition"
                  >
                    Copy
                  </button>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-800">
              <button
                onClick={() => toast.remove(toastId)}
                className="w-full p-4 flex place-items-center font-medium text-secondary-400 hover:text-secondary-300"
              >
                Close
              </button>
            </div>
          </div>
        ),
        {
          duration: 60000,
          id: toastId,
        },
      );
    } catch (e) {
      toast.error('Error creating token', { id: toastId });
    }
    refreshAPITokens();
  };

  const handleRevokeAPIToken = (tokenId: string) => {
    toast
      .promise(
        revokeAPIToken(tokenId),
        {
          error: 'Error revoking token',
          loading: 'Revoking token...',
          success: 'Token revoked',
        },
        { id: 'api-token-revoke' },
      )
      .then(refreshAPITokens);
  };

  return (
    <div className="py-8">
      <div className="flex w-full items-center justify-between pr-2">
        <H2>API Tokens</H2>

        <div className="flex gap-2">
          <div className="w-auto max-w-xs">
            <Select
              id="environment"
              value={createTokenEnvironment}
              onChange={(event) =>
                setCreateTokenEnvironment(event.target.value as Environment)
              }
            >
              {environments.map((environment) => (
                <option key={environment} value={environment}>
                  {environment}
                </option>
              ))}
            </Select>
          </div>
          <Button
            onClick={() => {
              const label = prompt(
                `Label the token so it can be easily identified.`,
              );
              if (label) {
                handleCreateAPIToken(label);
              }
            }}
          >
            Create
          </Button>
        </div>
      </div>

      <div className="mt-4">
        <ul className="space-y-1 rounded-lg bg-secondary-50 p-4 text-sm">
          <li>
            API tokens are managed at an organization level and shared by users.
          </li>
          <li>
            For more information on Pngme's API token management system, see our{' '}
            <ExternalLink
              secondary
              href="https://developers.api.pngme.com/reference/quickstart-guide"
            >
              Quickstart Guide
            </ExternalLink>
          </li>
        </ul>
      </div>

      <div className="mt-4">
        <table className="min-w-full text-sm">
          <tbody className="divide-y divide-gray-200">
            {apiTokens.map((apiToken) => {
              return (
                <tr key={apiToken.id}>
                  <td className="p-3 pl-2 font-medium">
                    <div>{apiToken.label}</div>
                    {apiToken.isInternal && (
                      <Pill secondary>
                        <span className="whitespace-nowrap">
                          Internal | Non-billable
                        </span>
                      </Pill>
                    )}
                  </td>
                  <td className="p-3 pl-2 text-gray-500 hidden md:block">
                    <div>{apiToken.token}</div>
                    <div>{apiToken.environment}</div>
                  </td>
                  <td className="p-3">
                    <div>{apiToken.createdBy}</div>
                    <div>Created {dayjs(apiToken.createdAt).fromNow()}</div>
                  </td>
                  <td className="p-3 pr-0 text-right">
                    <Button
                      transparent
                      disabled={
                        !['admin', 'owner'].includes(claims.organizationRole)
                      }
                      onClick={() =>
                        window.confirm(
                          `Are you sure you want to revoke "${apiToken.label}" created by ${apiToken.createdBy}?`,
                        ) && handleRevokeAPIToken(apiToken.id)
                      }
                    >
                      Revoke
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default APITokensContainer;

import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import ErrorBoundary from '../components/ErrorBoundary';
import LoadingSpinner from '../components/LoadingSpinner';
import Navbar from '../components/Navbar';
import useAuth from '../hooks/useAuth';

const AuthenticatedLayout = () => {
  const { isAuthenticated } = useAuth();
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }
  return (
    <>
      <div className="px-4 sm:px-6 md:px-8">
        <Navbar />
      </div>
      <main>
        <div className="p-4 sm:p-6 md:p-8">
          <Suspense fallback={<LoadingSpinner />}>
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </Suspense>
        </div>
      </main>
    </>
  );
};

export default AuthenticatedLayout;

import { NavLink as RouterNavLink, NavLinkProps } from 'react-router-dom';

import classNames from '../lib/classNames';

const NavLink = ({ ...props }: NavLinkProps) => {
  return (
    <RouterNavLink
      {...props}
      className={({ isActive }) =>
        classNames(
          isActive ? 'bg-gray-100 hover:bg-gray-200' : 'hover:bg-gray-100',
          'rounded-lg px-4 py-2',
        )
      }
    />
  );
};

export default NavLink;

import { Link as RouterLink, LinkProps } from 'react-router-dom';

import classNames from '../lib/classNames';

type Props = {
  primary?: boolean;
  secondary?: boolean;
} & LinkProps;

const Link = ({ primary, secondary, ...props }: Props) => {
  var color;
  if (primary) {
    color = 'text-primary-600 hover:text-primary-700';
  } else if (secondary) {
    color = 'text-secondary-500 hover:text-secondary-500';
  } else {
    color = 'text-gray-800 hover:text-gray-900';
  }
  return <RouterLink {...props} className={classNames(color, 'font-medium')} />;
};

export default Link;

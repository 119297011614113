import { ComponentType } from 'react';
import { useLocation } from 'react-router-dom';

type WithCurrentLocationProps = {
  currentLocation: string;
};

const withCurrentLocation = <P,>(
  Component: ComponentType<P & WithCurrentLocationProps>,
) => {
  return (props: Omit<P, keyof WithCurrentLocationProps>) => {
    const location = useLocation();
    return <Component currentLocation={location.key} {...(props as P)} />;
  };
};

export default withCurrentLocation;

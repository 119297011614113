import NavLink from '../../components/NavLink';
import setTitle from '../../lib/setTitle';

type Props = {
  userId: string;
  name: string;
  externalId: string;
};

const BreadcrumbNav = ({ userId, name, externalId }: Props) => {
  let navLinkAttribute;
  if (name.trim().length === 0 && externalId) {
    setTitle('Data | ' + externalId);
    navLinkAttribute = externalId;
  } else {
    setTitle('Data | ' + name);
    navLinkAttribute = name;
  }

  return (
    <nav>
      <ol className="flex items-center gap-4 text-sm">
        <li>
          <NavLink to="/users" end>
            Data
          </NavLink>
        </li>

        <li className="text-gray-600">/</li>

        <li>
          <NavLink to={'/users/' + userId}>{navLinkAttribute}</NavLink>
        </li>
      </ol>
    </nav>
  );
};

export default BreadcrumbNav;

import { KeyIcon } from '@heroicons/react/24/outline';

import H1 from '../../components/H1';
import Link from '../../components/Link';
import useAPITokens from '../../hooks/useAPITokens';
import { trackApiTokenCopied } from '../../lib/tracking';
import UndrawTeam from '../../static/img/undraw_team_primary.svg';

const DemoAPITokenContainer = () => {
  const { apiTokens } = useAPITokens();

  if (apiTokens.length < 1) {
    return <></>;
  }

  const token = apiTokens[0].token;
  return (
    <div className="w-fit mx-auto">
      <div className="bg-gray-900 text-gray-50 text-sm shadow-lg rounded-lg flex transition">
        <div className="flex-1 p-4">
          <div className="flex place-items-center">
            <KeyIcon className="text-green-500 w-6 h-6" />

            <div className="mx-3 space-y-0.5 max-w-full">
              <p className="font-bold flex-grow">Demo API token</p>

              <p>
                Use this token to test Pngme&apos;s APIs. See{' '}
                <a
                  className="font-medium text-secondary-400 hover:text-secondary-300"
                  href="https://developers.api.pngme.com/reference"
                >
                  API docs
                </a>{' '}
                for details.
              </p>

              <p className="text-xs text-gray-500 truncate">{token}</p>
            </div>
          </div>
        </div>
        <div className="flex border-l border-gray-800">
          <button
            onClick={() =>
              navigator.clipboard
                .writeText(token)
                .then(() => trackApiTokenCopied('demo'))
            }
            className="flex p-4 items-center justify-center font-medium text-secondary-400 hover:text-secondary-300 focus:text-green-500 transition"
          >
            Copy
          </button>
        </div>
      </div>
    </div>
  );
};

const DemoOrganizationContainer = () => {
  return (
    <div>
      <div className="flex justify-center">
        <div className="mt-12">
          <div className="flex justify-center">
            <img src={UndrawTeam} className="mb-8 h-32" alt="Team of people" />
          </div>

          <div className="w-full max-w-md mx-auto">
            <H1>Demo Organization</H1>

            <div className="mt-2 text-sm w-full md:w-96">
              <Link secondary to="/">
                Select an organization
              </Link>{' '}
              that is not <b>pngmedemo</b> to manage members and billing.
            </div>
          </div>

          <div className="mt-8">
            <DemoAPITokenContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoOrganizationContainer;

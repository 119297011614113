import useApiCache from './useApiCache';
import useConsoleUserToken from './useConsoleUserToken';

export type OrganizationMember = {
  firstName?: string;
  lastName?: string;
  email: string;
  role: 'basic' | 'admin' | 'owner';
  isInvited: boolean;
};

export type Response = {
  members: OrganizationMember[];
};

const useOrganizationMembers = (organizationUuid: string) => {
  const { token } = useConsoleUserToken();
  const path = `/beta/dashboard/organizations/${organizationUuid}/members`;
  const { data, error, refresh } = useApiCache<Response>(path, token);
  return {
    error,
    members: data?.members,
    refreshMembers: refresh,
  };
};

export default useOrganizationMembers;

import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { getWithToken } from '../lib/api';
import {
  ClientTokenClaims,
  ConsoleUserTokenClaims,
  decodeToken,
} from '../lib/auth';

/**
 * Provide a thin abstraction over useQuery to enforce consistent caching
 * behavior. Should not be used directly in presentation components, which
 * should prefer to consume API responses through other hooks.
 */
const useApiCache = <T,>(path: string, token: string) => {
  const queryClient = useQueryClient();

  const cacheKey = useMemo(() => {
    const { sub, subType } = decodeToken<
      ConsoleUserTokenClaims | ClientTokenClaims
    >(token);

    return [path, sub, subType];
  }, [path, token]);

  const fetcher = useCallback(
    () => getWithToken<T>(path, token),
    [path, token],
  );

  const { data, error } = useQuery(cacheKey, fetcher, { suspense: true });

  const refresh = useCallback(() => {
    queryClient.invalidateQueries(cacheKey);
  }, [cacheKey, queryClient]);

  if (data === undefined) {
    throw Error('data undefined but suspense is enabled');
  }

  return { data, error, refresh };
};

export default useApiCache;

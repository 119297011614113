import { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import LoadingSpinner from './components/LoadingSpinner';
import GlobalContextProvider from './context';
import AuthenticatedLayout from './layouts/AuthenticatedLayout';
import GlobalLayout from './layouts/GlobalLayout';
import UnauthenticatedLayout from './layouts/UnauthenticatedLayout';
import setTitle from './lib/setTitle';
import DashboardPage from './routes/Dashboard';
import ForgotPasswordPage from './routes/ForgotPassword';
import HomePage from './routes/Home';
import LeadManagementPage from './routes/LeadManagement';
import LoginPage from './routes/Login';
import NotFoundPage from './routes/NotFound';
import OrganizationPage from './routes/Organization';
import OrganizationCreatePage from './routes/OrganizationCreate';
import SettingsPage from './routes/Settings';
import SignupPage from './routes/Signup';
import UpdatePasswordPage from './routes/UpdatePassword';
import UserPage from './routes/User';
import UsersPage from './routes/Users';

const App = () => {
  setTitle('Pngme Dashboard');

  return (
    <BrowserRouter>
      <GlobalContextProvider>
        <GlobalLayout>
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route path="/" element={<AuthenticatedLayout />}>
                <Route index element={<HomePage />} />
                <Route path="dashboard" element={<DashboardPage />} />
                <Route
                  path="profile"
                  element={<Navigate to="/settings" replace />}
                />
                <Route path="settings" element={<SettingsPage />} />
                <Route path="leads" element={<LeadManagementPage />} />
                <Route path="organization">
                  <Route index element={<OrganizationPage />} />
                  <Route path="create" element={<OrganizationCreatePage />} />
                </Route>
                <Route path="users">
                  <Route index element={<UsersPage />} />
                  <Route path=":userId/*" element={<UserPage />} />
                </Route>
                <Route path="*" element={<NotFoundPage />} />
              </Route>

              <Route path="/" element={<UnauthenticatedLayout />}>
                <Route
                  path="forgot-password"
                  element={<ForgotPasswordPage />}
                />
                <Route path="signup" element={<SignupPage />} />
                <Route path="login" element={<LoginPage />} />
                <Route
                  path="update-password"
                  element={<UpdatePasswordPage />}
                />
              </Route>
            </Routes>
          </Suspense>
        </GlobalLayout>
      </GlobalContextProvider>
    </BrowserRouter>
  );
};

export default App;

import {
  ArrowDownCircleIcon,
  PlusCircleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import Button from '../../components/Button';
import H1 from '../../components/H1';
import H3 from '../../components/H3';
import Input from '../../components/Input';
import Select from '../../components/Select';
import useDebounce from '../../hooks/useDebounce';
import useLeadsCount from '../../hooks/useLeadsCount';
import { downloadAsCSV } from '../../lib/csv';
import {
  findValidFilters,
  getLeads,
  LeadFilterField,
  leadFilterFields,
} from '../../lib/leads';

type FilterItem = {
  id: string;
  field: string;
  operation: string;
  value: number;
  help?: string;
};

const filterDescriptionByOperator = {
  '<': 'is less than',
  '>': 'is greater than',
} as const;
type FilterOperator = keyof typeof filterDescriptionByOperator;

const filterOperatorByDescription = {
  'is greater than': '>',
  'is less than': '<',
} as const;
const filterOperatorDescriptions = Object.keys(filterOperatorByDescription);
type FilterOperatorDescription = keyof typeof filterOperatorByDescription;

/* eslint-disable camelcase */
export const filterMeta: {
  [k in LeadFilterField]: {
    defaultOperation: FilterOperator;
    defaultValue: number;
    help: string;
  };
} = {
  count_betting_and_lottery_events_0_90: {
    defaultOperation: '<',
    defaultValue: 1,
    help: 'Number of SMS received indicating gambling activity in the past 90 days.',
  },
  count_loan_defaulted_events_0_90: {
    defaultOperation: '<',
    defaultValue: 1,
    help: 'Number of SMS received indicating a loan default in the past 90 days.',
  },
  count_loan_missed_payment_events_0_90: {
    defaultOperation: '<',
    defaultValue: 1,
    help: 'Number of SMS received that indicate a missed loan payment in the past 90 days.',
  },
  count_loan_opened_events_0_90: {
    defaultOperation: '>',
    defaultValue: 0,
    help: 'Number of SMS received that indicate a new loan was opened in the past 90 days.',
  },
  count_loan_repayment_events_0_90: {
    defaultOperation: '>',
    defaultValue: 0,
    help: 'Number of SMS received that indicate a loan repayment was made.',
  },
  count_user_shared_device_ids_0_10: {
    defaultOperation: '<',
    defaultValue: 2,
    help: 'Number of users registered using the same physical device.',
  },
  income_kes_per_year: {
    defaultOperation: '>',
    defaultValue: 100000,
    help: 'Estimated annual income.',
  },
  mpesa_sum_of_credits_0_90: {
    defaultOperation: '>',
    defaultValue: 10000,
    help: 'Total of M-Pesa credit transactions in the last 90 days.',
  },
  risk_score: {
    defaultOperation: '<',
    defaultValue: 0.25,
    help: 'Estimated probability of a missed payment for a 30 day loan.',
  },
  sum_of_credits_0_90: {
    defaultOperation: '<',
    defaultValue: 10000,
    help: 'Total of credit transactions across all accounts in the last 90 days.',
  },
  sum_of_depository_balances_latest: {
    defaultOperation: '>',
    defaultValue: 10000,
    help: 'Total balance held summed across all depository accounts in local currency.',
  },
};
/* eslint-enable camelcase */

const createEmptyFilterItem = (): FilterItem => ({
  field: '',
  id: uuidv4(),
  operation: 'is less than',
  value: NaN,
});

const defaultFilterItems = [createEmptyFilterItem()];

const useFilterItems = () => {
  const [filterItems, setFilterItems] = useState(defaultFilterItems);

  const addFilterItem = () => {
    setFilterItems((prev) => [...prev, createEmptyFilterItem()]);
  };

  const removeFilterItem = (item: FilterItem) => {
    setFilterItems((prevItems) => {
      const nextItems = prevItems.filter((prevItem) => prevItem.id !== item.id);
      if (nextItems.length === 0) {
        return [createEmptyFilterItem()];
      }
      return nextItems;
    });
  };

  const updateFilterItem = (item: FilterItem) => {
    setFilterItems((prevItems) => {
      const index = prevItems.findIndex((prevItem) => prevItem.id === item.id);
      const nextItems = [...prevItems];
      nextItems[index] = item;
      return nextItems;
    });
  };

  const clearFilterItems = () => {
    setFilterItems([createEmptyFilterItem()]);
  };

  return {
    addFilterItem,
    clearFilterItems,
    filterItems,
    removeFilterItem,
    updateFilterItem,
  };
};

const LeadManagementContainer = () => {
  const [isFetchingLeads, setIsFetchingLeads] = useState(false);

  const {
    filterItems,
    addFilterItem,
    removeFilterItem,
    updateFilterItem,
    clearFilterItems,
  } = useFilterItems();

  // Use valid filter items to fetch count of leads
  const validFilterItems = useMemo(
    () => findValidFilters(filterItems),
    [filterItems],
  );
  const validFilterItemsDebounced = useDebounce(validFilterItems);
  const { leadsCount, isRefetchingLeadsCount } = useLeadsCount(
    validFilterItemsDebounced,
  );

  // TODO: how should we configure lead management for demo org? need to seed
  // separate sample data
  // if (activeClient.type === 'demo') { return
  // <DemoOrganizationSplash />;
  // }

  const handleGetLeads = async () => {
    try {
      setIsFetchingLeads(true);
      const leads = await getLeads(filterItems);
      if (leads === null) {
        return;
      }
      downloadAsCSV(leads, 'leads.csv');
      clearFilterItems();
    } finally {
      setIsFetchingLeads(false);
    }
  };

  const handleSetFilterItemField = (filterItem: FilterItem, field: string) => {
    if (!(field in filterMeta)) {
      removeFilterItem(filterItem);
      return;
    }
    const meta = filterMeta[field as LeadFilterField];
    const nextFilterItem = {
      ...filterItem,
      field,
      help: meta.help,
      operation: meta.defaultOperation,
      value: meta.defaultValue,
    };

    updateFilterItem(nextFilterItem);
  };

  return (
    <div className="mx-auto max-w-2xl">
      <H1>Lead Management</H1>

      <p className="mt-4">
        Pngme's Lead Management platform enables you to search for users who fit
        a target financial profile.
      </p>

      <div className="mt-6">
        <form>
          {/* TODO: responsive */}
          <H3>Filters</H3>
          <ul className="my-2 space-y-2">
            {filterItems.map((filterItem) => (
              <li key={filterItem.id}>
                <div className="flex place-items-center gap-2">
                  <Select
                    onChange={(e) =>
                      handleSetFilterItemField(filterItem, e.target.value)
                    }
                  >
                    <option />
                    {leadFilterFields.map((field) => (
                      <option key={field}>{field}</option>
                    ))}
                  </Select>

                  <Select
                    onChange={(e) => {
                      const description = e.target
                        .value as FilterOperatorDescription;
                      const operation =
                        filterOperatorByDescription[description];
                      updateFilterItem({
                        ...filterItem,
                        operation,
                      });
                    }}
                    value={
                      filterDescriptionByOperator[
                        filterItem.operation as FilterOperator
                      ]
                    }
                  >
                    {filterOperatorDescriptions.map((operation) => (
                      <option key={operation}>{operation}</option>
                    ))}
                  </Select>

                  <Input
                    type="number"
                    onChange={(e) =>
                      updateFilterItem({
                        ...filterItem,
                        value: parseFloat(e.target.value),
                      })
                    }
                    value={filterItem.value || ''}
                  />

                  <button
                    className="p-2 text-gray-600 hover:text-gray-900"
                    onClick={(e) => {
                      e.preventDefault();
                      removeFilterItem(filterItem);
                    }}
                  >
                    <XCircleIcon className="h-4 w-4" />
                  </button>
                </div>
                {filterItem.help && (
                  <p className="mt-2 mb-3 mx-2 flex place-items-center">
                    <QuestionMarkCircleIcon className="h-4 w-4 mr-1 text-primary-500" />{' '}
                    {filterItem.help}
                  </p>
                )}
              </li>
            ))}
          </ul>

          <div className="mt-4 flex gap-2">
            <Button
              onClick={(e) => {
                e.preventDefault();
                addFilterItem();
              }}
            >
              <span className="flex place-items-center gap-1">
                <PlusCircleIcon className="h-4 w-4" /> Add filter
              </span>
            </Button>

            <Button
              primary
              onClick={(e) => {
                e.preventDefault();
                handleGetLeads();
              }}
              disabled={isFetchingLeads || isRefetchingLeadsCount}
            >
              <span className="flex place-items-center gap-1">
                <ArrowDownCircleIcon className="h-4 w-4" /> Download{' '}
                {leadsCount} leads
              </span>
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LeadManagementContainer;

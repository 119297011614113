/**
 * This module exposes organization administration services.
 */

import { postWithToken } from './api';
import { getConsoleUserToken } from './auth';

export type CreateOrganizationResponse = {
  organizationName: string;
  organizationUuid: string;
};

/** Create a new organization with the given name. */
export const createOrganization = async (organizationName: string) => {
  const token = getConsoleUserToken();
  return await postWithToken<CreateOrganizationResponse>(
    '/beta/dashboard/organizations',
    { organization_name: organizationName }, // eslint-disable-line camelcase
    token,
  );
};

import useApiCache from '../hooks/useApiCache';
import useConsoleUserToken from './useConsoleUserToken';

type Response = {
  country: string;
  organizationName: string;
  isLeadManagementEnabled: boolean;
};

const useOrganization = (organizationId: string) => {
  const { token } = useConsoleUserToken();
  const path = `/beta/dashboard/organizations/${organizationId}`;
  const { data, error } = useApiCache<Response>(path, token);
  return { error, organization: data };
};

export default useOrganization;

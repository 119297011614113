import { FormEvent, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSearchParams } from 'react-router-dom';

import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import ExternalLink from '../../components/ExternalLink';
import H2 from '../../components/H2';
import Label from '../../components/Label';
import LabeledInput from '../../components/LabeledInput';
import Link from '../../components/Link';
import Select from '../../components/Select';
import TextLogo from '../../components/TextLogo';
import useAuth from '../../hooks/useAuth';
import { SignupResult } from '../../lib/auth';
import countries from '../../lib/countries';
import setTitle from '../../lib/setTitle';
import validate from '../../lib/validate';

const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
if (!recaptchaSiteKey) {
  throw Error('Unable to start without valid captcha site key.');
}

const SignupPage = () => {
  setTitle('Sign up');

  const [searchParams] = useSearchParams();
  const defaultEmail = searchParams.get('email') || '';

  const { signup } = useAuth();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState(defaultEmail);
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('');
  const [isAgreedToToS, setIsAgreedToToS] = useState(false);

  const recaptchaRef = useRef<any>();

  const [isExistingEmail, setIsExistingEmail] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false);
  const [showPhoneNumberSubText, setShowPhoneNumberSubText] = useState(false);

  const isFormValid =
    !isLoading &&
    !!firstName.length &&
    !!lastName.length &&
    !!country.length &&
    isEmailValid &&
    isPasswordValid &&
    isPhoneNumberValid &&
    isAgreedToToS;

  useEffect(() => {
    setShowPasswordRequirements((prev) => prev || !isPasswordValid);
  }, [isPasswordValid]);

  useEffect(() => {
    setShowPhoneNumberSubText((prev) => prev || !isPhoneNumberValid);
  }, [isPhoneNumberValid]);

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isFormValid) {
      return;
    }

    setIsLoading(true);
    recaptchaRef.current
      .executeAsync()
      .then((recaptchaResponse: string) =>
        signup({
          country,
          email,
          firstName,
          lastName,
          password,
          phoneNumber,
          recaptchaResponse,
        }),
      )
      .then((signupResult: SignupResult) =>
        setIsExistingEmail(signupResult.isExistingEmail),
      )
      .catch(() => {
        setIsLoading(false);
        setIsEmailValid(false);
        setIsPasswordValid(false);
        setIsPhoneNumberValid(false);
        recaptchaRef.current.reset();
      });
  };

  return (
    <div className="w-full md:w-96">
      <div className="flex justify-center py-12">
        <div className="cursor-default text-4xl">
          <TextLogo />
        </div>
      </div>

      <H2>Sign up</H2>

      <p className="mt-2 text-sm">
        Register for a{' '}
        <span className="font-medium text-primary-600">Pngme</span> account to
        start building with{' '}
        <span className="font-medium text-primary-600">Pngme</span>'s financial
        insights for free.
      </p>

      <form className="mt-2 w-full" onSubmit={handleFormSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <LabeledInput
            id="firstName"
            label="First name"
            type="text"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
          />
          <LabeledInput
            id="lastName"
            label="Last name"
            type="text"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
          />
        </div>

        <LabeledInput
          id="email"
          label="Work Email"
          type="text"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          onBlur={() => setIsEmailValid(validate.email(email))}
          error={!isEmailValid || isExistingEmail}
          disabled={!!defaultEmail}
          subText={isExistingEmail ? 'Email is already registered.' : undefined}
        />

        <LabeledInput
          id="password"
          label="Password"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          onBlur={() => setIsPasswordValid(validate.password(password))}
          error={!isPasswordValid}
          subText={
            showPasswordRequirements
              ? 'Minimum 8 characters, at least one capital letter, number, and special character.'
              : undefined
          }
        />

        <LabeledInput
          id="phone"
          label="Phone Number"
          type="text"
          value={phoneNumber}
          onChange={(event) => setPhoneNumber(event.target.value)}
          onBlur={() =>
            setIsPhoneNumberValid(validate.phoneNumber(phoneNumber))
          }
          error={!isPhoneNumberValid}
          subText={
            showPhoneNumberSubText ? 'Enter a valid phone number' : undefined
          }
        />

        <div className="space-y-2 py-3">
          <Label>Country</Label>
          <Select
            id="country"
            onChange={(event) => setCountry(event.target.value)}
          >
            <option />
            {countries.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </Select>
        </div>

        <div className="mt-3 flex items-center gap-2">
          <Checkbox
            id="hasAgreedToToS"
            checked={isAgreedToToS}
            onChange={() => setIsAgreedToToS((prev) => !prev)}
          />
          <label className="text-sm" htmlFor="hasAgreedToToS">
            I agree to Pngme{' '}
            <ExternalLink primary href="https://pngme.com/terms-of-service">
              Terms
            </ExternalLink>{' '}
            and{' '}
            <ExternalLink primary href="https://pngme.com/privacy-policy">
              Privacy Policy
            </ExternalLink>
          </label>
        </div>

        <div className="mt-6">
          <Button disabled={!isFormValid} wide>
            Create account
          </Button>
        </div>
      </form>

      <div className="mt-6 text-sm">
        Have an account?{' '}
        <Link primary to="/login">
          Sign in
        </Link>
      </div>

      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={recaptchaSiteKey}
        size="invisible"
      />
    </div>
  );
};

export default SignupPage;

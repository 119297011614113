import { Suspense } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import LoadingSpinner from '../../components/LoadingSpinner';
import useUsers from '../../hooks/useUsers';
import BreadcrumbNav from './BreadcrumbNav';
import PrimitiveData from './PrimitiveData';
import UserFinancialInsights from './UserFinancialInsights';
import UserProfile from './UserProfile';

const UserPage = () => {
  const { userId } = useParams();
  const { users } = useUsers(userId);

  if (userId === undefined || users === undefined) {
    return (
      <div className="my-24 flex justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  const user = users.find((item) => item.uuid === userId);

  if (user === undefined) {
    return <Navigate to="/users" />;
  }

  const name = user.firstName + ' ' + user?.lastName;
  return (
    <div>
      <BreadcrumbNav userId={userId} name={name} externalId={user.externalId} />

      <UserProfile user={user} />

      <Suspense fallback={<LoadingSpinner />}>
        <UserFinancialInsights userId={user.uuid} />
        <PrimitiveData userCreatedAt={user.createdAt} userId={userId} />
      </Suspense>
    </div>
  );
};

export default UserPage;
